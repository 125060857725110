import {
    Button,
    useNotify,
    useDataProvider,
    SimpleForm,
    Toolbar,
    SaveButton,
    LinearProgress, TextInput, RecordContextProvider
} from "react-admin";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {Dialog, DialogTitle, DialogContent, DialogActions, } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import {E55cApiDataProvider} from "../../../../../providers/dataProvider";

export const ButtonInvoicingParameters = () => {
    const [open, setOpen] = useState(false);

    const translate = useCallback((str: string) => str, []);

    const handleClose = () => setOpen(false);

    return <>
        <Button onClick={() => setOpen(true)} label={translate('Paramètres')} variant={'outlined'} data-testid="toggleParametersModal" />
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{translate('Paramétrage de l\'invoicing')}</DialogTitle>
            <DialogContent>
                {open && <ParametersForm />}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} label={translate('Annuler')} />
            </DialogActions>
        </Dialog>
    </>
}

const ParametersForm = () => {
    const [loading, setLoading] = useState(false);
    const provider = useDataProvider<E55cApiDataProvider>();
    const notify = useNotify();

    const handleSubmit = (data: any) => {
        setLoading(true);
        provider.saveInvoicingConfig(data)
            .then((json: any) => {
                notify(`Paramètres sauvegardés`);
            })
            .catch((e: any) => {
                if (e.message) {
                    notify(`Erreur : ${e.message}`, {type: "error"});
                }
                else {
                    notify('Erreur de sauvegarde des paramètres', {type: "error"});
                }
            })
            .finally(() => setLoading(false));
    }

    return (<ParametersFetcher><SimpleForm onSubmit={handleSubmit} toolbar={<FormToolbar loading={loading}/>} data-testid="syncForm">
        <TextInput source={'to'} name={'to'} label={'Destinataires To:'} required />
        <TextInput source={'cc'} name={'cc'} label={'Destinataires Cc:'} />
        <TextInput source={'from'} name={'from'} label={'Expéditeur From:'} required />
    </SimpleForm></ParametersFetcher>);
}

const ParametersFetcher = ({children}: {children: any}) => {
    const provider = useDataProvider<E55cApiDataProvider>();
    const [data, setData] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        provider.getInvoicingConfig()
            .then((resp: any) => setData(resp))
            .catch(() => setError('erreur de chargement des paramètres'))
            .finally(() => setIsLoading(false));
    }, [provider]);

    if (isLoading) return <p>Chargement...</p>;
    if (error) return <p>Erreur de chargement</p>;

    return (
        <RecordContextProvider value={data}>
            {children}
        </RecordContextProvider>
    );
}

const FormToolbar = ({loading}: {loading: boolean}) => {
    return <Toolbar sx={{justifyContent: "center"}}>
        {loading ? <LinearProgress/> : <SaveButton icon={<SettingsIcon/>} data-testid="saveParamsButton"/>}
    </Toolbar>
}
