import {Button, useDataProvider, useNotify} from "react-admin";
import {useState} from "react";
import {E55cApiDataProvider} from "../../../../../providers/dataProvider";

const SendInvoiceButton = ({issuerId, invoiceId}: {issuerId: string, invoiceId: string}) => {
    const provider = useDataProvider<E55cApiDataProvider>();
    const [loading, setLoading] = useState(false);
    const notify = useNotify();

    const handleDl = () => {
        setLoading(true);
        provider.sendInvoice({issuerId, invoiceId})
            .then((response: Response) => {
                if (!response.status) {
                    throw new Error('invalid response');
                }

                notify("Détails de facturation envoyés");
            })
            .catch(err => notify(err.toString(), {type: 'error'}))
            .finally(() => setLoading(false))
    }

    return <Button onClick={handleDl} label={'Envoyer'} variant={'outlined'} disabled={loading}/>;
}

export default SendInvoiceButton;
