import {ButtonProps, useSimpleFormIterator, useTranslate} from "react-admin";
import React from "react";
import Button from "@mui/material/Button";

export const AddRowButton = (props: ButtonProps) => {
    const { add } = useSimpleFormIterator();
    const translate = useTranslate();

    return (
        <Button
            onClick={() => add()}
            variant={'outlined'}
            {...props}
        >
            {translate(props.label ?? 'ra.action.add')}
        </Button>
    );
};
