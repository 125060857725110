import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Chip from "@mui/material/Chip";

import { DeployPlanning } from '../../../../providers/dto/DeployPlanning';
import { useDataProvider, useNotify, useTranslate } from 'react-admin';
import dataProvider from '../../../../providers/dataProvider';
import { useEffect, useState } from 'react';
import DashboardSection from '../DashboardSection';

export const DeploymentTable: React.FC = () => {
    const[deployPlanning, setDeployPlanning] = useState({planning : []} as DeployPlanning)
    const provider = useDataProvider<typeof dataProvider>();
    const notify = useNotify();
    const translate = useTranslate();

    useEffect(() => {
        provider.getDeployPlanning()
        .then((res) => { setDeployPlanning(res) })
        .catch((err: any) => notify(err.toString(), {type: 'error'}))
    }, [provider, notify])

    return (
        <DashboardSection title='Planning prévisionnel'>
            <TableContainer sx={{ minWidth : 320, width: '85vw'}} component={Paper}>
                <Table size="medium" aria-label="Planning Déploiement">
                    <TableHead>
                        <TableRow>
                            <TableCell>{translate("exploitation.stats.deployment.table.month")}</TableCell>
                            <TableCell>{translate("exploitation.stats.deployment.table.previsional")}</TableCell>
                            <TableCell>{translate("exploitation.stats.deployment.table.adjusted")}</TableCell>
                            <TableCell>{translate("exploitation.stats.deployment.table.real")}</TableCell>
                            <TableCell>{translate("exploitation.stats.deployment.table.late")}</TableCell>
                            <TableCell>{translate("exploitation.stats.deployment.table.note")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {deployPlanning.planning.map((line, index) => (
                            <TableRow
                                key={line[0]}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell>
                                    { line[0] }
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    { line[1] }
                                </TableCell>
                                <TableCell>
                                    { line[2] }
                                </TableCell>
                                <TableCell>
                                    <Chip label={line[3]} sx={{backgroundColor: GetChipColor(line[3], line[1]), color: "white"}}></Chip>
                                </TableCell>
                                <TableCell>
                                    {line[4]}
                                </TableCell>
                                <TableCell>
                                    { line[5] }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </DashboardSection>
    );
}

function GetChipColor(value: number, max: number): string {
    const colors: [string, string, string] = ["green", "orange", "red"]
    return max === value ? colors[0] : (max / 2 < value ? colors[1] : colors[2])
}
