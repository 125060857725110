import {
    DatagridConfigurable,
    DateField,
    FilterButton,
    FunctionField,
    List, SelectColumnsButton,
    ShowButton,
    TextField,
    TopToolbar, useTranslate, WrapperField
} from "react-admin";
import {Chargebox} from "../../../data/exploitation/chargebox";
import SearchHintInput from "../../../components/input/SearchHintInput";
import TagsColumn from "./components/TagsColumn";
import TagsInputFilter from "./components/TagsInputFilter";
import OperationsColumn from "./components/OperationsColumn";
import ChargeboxesSubMenuBar from "../components/ChargeboxesSubMenuBar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {toFrenchDate} from "../../../../helpers/helper";
import Chip from "@mui/material/Chip";

const ListActions = (props: any) => {
    return <TopToolbar>
        <FilterButton/>
        <SelectColumnsButton/>
    </TopToolbar>;
}

const QuickFilter = ({
                         label,
                     }: {
    label: string;
    source?: string;
    defaultValue?: any;
}) => {
    const translate = useTranslate();
    return <Chip sx={{ marginBottom: 1 }} label={translate(label)} />;
};

const listFilters = [
    <SearchHintInput source="q" alwaysOn key={'search'} hint={"Rechercher une chaine de caracteres (sensible à la casse) parmi : chargebox id, evse uid."} />,
    <QuickFilter source="hasPendingOperations" key={'hasPendingOperations'} label={"resources.chargeboxes.fields.has_pending_operations"} defaultValue={true}/>,
    <TagsInputFilter source="tags" />
];

export default function ChargeboxList() {
    return (
        <>
        <ChargeboxesSubMenuBar/>
        <List<Chargebox> sort={{field: "chargeBoxId", order: "ASC"}} actions={<ListActions/>} filters={listFilters}>
            <DatagridConfigurable rowClick="false" bulkActionButtons={false} omit={['created']}>
                <TextField source="chargeBoxId" sortable={true}/>
                <FunctionField source="evses" sortable={false} render={(record : Chargebox) => {
                    return record.evses?.map((evse, k) => <Card variant={'outlined'} key={k}>
                        <CardContent>
                            <Typography variant="body1" color="text.primary">
                                {evse.uid}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {evse.status}
                            </Typography>
                            {evse.statusSince && <Typography variant="body2" color="text.secondary">
                                {toFrenchDate(evse.statusSince)}
                            </Typography>}
                        </CardContent>
                    </Card>);
                }}/>
                <TagsColumn source={"tags"}/>
                <OperationsColumn source="operations" label={'resources.chargeboxes.fields.pending_operations'}/>
                <DateField source="lastUpdated" sortable={true} showTime={true} locales={'fr-FR'}/>
                <DateField source="created" sortable={true} showTime={true} locales={'fr-FR'}/>
                <WrapperField label="Actions">
                    <ShowButton />
                </WrapperField>
            </DatagridConfigurable>
        </List>
        </>
    );
}
