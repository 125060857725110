import {
    ArrayField, BooleanField,
    Datagrid, DateField, DeleteButton,
    ListButton, ReferenceField,
    Show, SimpleShowLayout,
    TabbedShowLayout,
    TextField,
    TopToolbar, useRecordContext
} from "react-admin";
import TriggerNotificationStatusButton from "./components/TriggerNotificationStatusButton";
import TriggerRebootButton from "./components/TriggerRebootButton";
import {Chargebox} from "../../../data/exploitation/chargebox";
import TagsColumn from "./components/TagsColumn";

const Actions = (props: any) => {
    return (
        <TopToolbar>
            <TriggerNotificationStatusButton/>
            <TriggerRebootButton/>
            <ListButton/>
            <DeleteButton/>
        </TopToolbar>
    );
}

const ChargeboxShowTitle = () => {
    const record = useRecordContext();

    return <span>Chargebox {record ? record.chargeBoxId : ''}</span>;
}

export default function ChargeboxShow() {
    return (
        <Show actions={<Actions/>} title={<ChargeboxShowTitle/>}>
            <Tabs/>
        </Show>
    );
}

const Tabs = () => {
    const record = useRecordContext<Chargebox>();

    return <TabbedShowLayout>
        <TabbedShowLayout.Tab label="resources.chargeboxes.show.tab.details">
            <TextField source="chargeBoxId" sortable={true}/>
            <ArrayField source="evses">
                <Datagrid bulkActionButtons={false} rowClick={false} expand={<ExpandEvse/>}>
                    <TextField source="uid" />
                    <TextField source="status" />
                    <DateField source="statusSince" showTime locales={'fr-FR'}/>
                </Datagrid>
            </ArrayField>
            <ReferenceField source="location.id" reference="exploitation-locations"/>
            <DateField source="lastUpdated" sortable={true} showTime={true} locales={'fr-FR'}/>
            <DateField source="created" sortable={true} showTime={true} locales={'fr-FR'}/>
            <TagsColumn source={"tags"}/>
        </TabbedShowLayout.Tab>
        {record?.ocppBootNotification && <TabbedShowLayout.Tab label="resources.chargeboxes.show.tab.boot">
            {Object.keys(record.ocppBootNotification).map((key) => {
                return <TextField key={key} source={`ocppBootNotification.${key}`}/>;
            })}
        </TabbedShowLayout.Tab>}
        <TabbedShowLayout.Tab label="resources.chargeboxes.show.tab.receivedMessages">
            <ArrayField source="receivedMessages" label={false} sort={{ field: 'date', order: 'DESC' }}>
                <Datagrid bulkActionButtons={false} rowClick={false} expand={<ExpandReceivedMessage/>}>
                    <DateField source="date" showTime={true} locales={'fr-FR'}/>
                    <TextField source="message.type"/>
                    <TextField source="message.uid"/>
                    <TextField source="message.evse.status"/>
                </Datagrid>
            </ArrayField>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="resources.chargeboxes.show.tab.taskSentMessages">
            <ArrayField source="sentTaskMessages" label={false} sort={{ field: 'date', order: 'DESC' }}>
                <Datagrid bulkActionButtons={false} rowClick={false}>
                    <DateField source="date" showTime={true} locales={'fr-FR'}/>
                    <TextField source="message.taskType"/>
                    <TextField source="message.key"/>
                </Datagrid>
            </ArrayField>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="resources.chargeboxes.show.tab.operations">
            <ArrayField source="operations" label={false} sort={{ field: 'started', order: 'DESC' }}>
                <Datagrid bulkActionButtons={false} rowClick={false}>
                    <DateField source="started" showTime={true} locales={'fr-FR'}/>
                    <TextField source="type"/>
                    <BooleanField source="scheduled"/>
                    <TextField source="status"/>
                    <TextField source="statusDetails"/>
                    <DateField source="completed" showTime={true} locales={'fr-FR'}/>
                </Datagrid>
            </ArrayField>
        </TabbedShowLayout.Tab>
    </TabbedShowLayout>;
}

function ExpandEvse() {
    const record = useRecordContext();

    if (!record) {
        return null;
    }

    return <SimpleShowLayout>
        {record?.ocppStatusNotification && Object.keys(record?.ocppStatusNotification).map((key) => {
            return <TextField key={key} source={`ocppStatusNotification.${key}`}/>
        })}
    </SimpleShowLayout>;
}

export function ExpandReceivedMessage() {
    const record = useRecordContext();

    switch (record?.message.type) {
        case 'BootNotification':
            return <ExpandBootNotification record={record}/>;
        case 'StatusNotification':
            return <ExpandStatusNotification record={record}/>;
    }

    return null;
}

function ExpandBootNotification({ record }: { record: any }) {
    if (!record) {
        return null;
    }

    return <SimpleShowLayout>
        {record.message.ocppBootNotification && Object.keys(record.message.ocppBootNotification).map((key) => {
            return <TextField key={key} source={`message.ocppBootNotification.${key}`}/>
        })}
    </SimpleShowLayout>;
}

function ExpandStatusNotification({ record }: { record: any }) {
    if (!record) {
        return null;
    }

    return <SimpleShowLayout>
        {record.message.ocppStatusNotification && Object.keys(record.message.ocppStatusNotification).map((key) => {
            return <TextField key={key} source={`message.ocppStatusNotification.${key}`}/>
        })}
    </SimpleShowLayout>;
}
