import {Button, Loading, useDataProvider, useNotify, useRecordContext, useRefresh} from "react-admin";
import QuestionMark from "@mui/icons-material/QuestionMark";
import {useState} from "react";
import dataProvider from "../../../../../providers/dataProvider";
import {Chargebox} from "../../../../data/exploitation/chargebox";

export default function TriggerNotificationStatusButton() {
    const [sending, setSending] = useState(false);
    const notify = useNotify();
    const provider = useDataProvider<typeof dataProvider>();
    const record = useRecordContext<Chargebox>();
    const refresh = useRefresh();

    const handleTrigger = () => {
        setSending(true);

        provider.triggerNotificationStatus(record!.id)
            .then(() => {
                notify('resources.chargeboxes.actions.trigger_notification_status.success', {type: 'success'});
                refresh();
            })
            .catch((error: any) => {
                notify(`Error: ${error.message}`, {type: 'error'});
            })
            .finally(() => {
                setSending(false);
            });
    }

    return (
        <Button
            label="resources.chargeboxes.actions.trigger_notification_status.label"
            variant={'contained'}
            color={'info'}
            onClick={handleTrigger} disabled={sending} endIcon={sending ? <Loading/> : <QuestionMark/>}/>
    );
}
