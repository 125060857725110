import {useEffect, useState} from "react";
import {
    email,
    LinearProgress,
    RecordContextProvider, SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    useDataProvider,
    useNotify
} from "react-admin";
import {E55cApiDataProvider} from "../../../providers/dataProvider";
import SettingsIcon from "@mui/icons-material/Settings";
import * as React from "react";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import ExploitationAdminPage from "./ExploitationAdminPage";

export default function ExploitationDailyStatsMailingConfigPage() {
    return <Card>
        <CardContent>
            <EditForm />
        </CardContent>
    </Card>;
}


const EditForm = () => {
    const [loading, setLoading] = useState(false);
    const provider = useDataProvider<E55cApiDataProvider>();
    const notify = useNotify();

    const handleSubmit = (data: any) => {
        setLoading(true);
        provider.saveDailyStatsMailingConfig({
            ...data,
            recipients: data.recipients.split(',').map((r: string) => r.trim())
        })
            .then((json: any) => {
                notify(`Paramètres sauvegardés`);
            })
            .catch((e: any) => {
                if (e.message) {
                    notify(`Erreur : ${e.message}`, {type: "error"});
                }
                else {
                    notify('Erreur de sauvegarde des paramètres', {type: "error"});
                }
            })
            .finally(() => setLoading(false));
    }

    return (<FormContext>
        <SimpleForm onSubmit={handleSubmit} toolbar={<FormToolbar loading={loading}/>} data-testid="syncForm">
            <TextInput source="recipients" label={'exploitation.admin.config.fields.recipients'} required />
            <TextInput source={'from'} name={'from'} label={'exploitation.admin.config.fields.from'} required validate={email()}/>
            <TextInput source={'prefix'} name={'prefix'} label={'exploitation.admin.config.fields.prefix'} required />
        </SimpleForm>
    </FormContext>);
}

const FormContext = ({children}: {children: any}) => {
    const provider = useDataProvider<E55cApiDataProvider>();
    const [data, setData] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');

    console.log(data, 'data')

    useEffect(() => {
        provider.getDailyStatsMailingConfig()
            .then((resp: any) => setData({
                ...resp,
                recipients: resp.recipients.join(', ')
            }))
            .catch(() => setError('erreur de chargement des paramètres'))
            .finally(() => setIsLoading(false));
    }, [provider]);

    if (isLoading) return <p>Chargement...</p>;
    if (error) return <p>Erreur de chargement</p>;

    return (
        <RecordContextProvider value={data}>
            {children}
        </RecordContextProvider>
    );
}

const FormToolbar = ({loading}: {loading: boolean}) => {
    return <Toolbar sx={{justifyContent: "center"}}>
        {loading ? <LinearProgress/> : <SaveButton icon={<SettingsIcon/>} data-testid="saveParamsButton"/>}
        <Button component={Link} to={ExploitationAdminPage.route}>Retour</Button>
    </Toolbar>
}

ExploitationDailyStatsMailingConfigPage.route = '/exploitation/daily-stats/mailing-config';
