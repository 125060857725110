import {Button, useDataProvider, useNotify} from "react-admin";
import {useState} from "react";
import {E55cApiDataProvider} from "../../../../../providers/dataProvider";

const DownloadInvoiceButton = ({issuerId, invoiceId}: {issuerId: string, invoiceId: string}) => {
    const provider = useDataProvider<E55cApiDataProvider>();
    const [loading, setLoading] = useState(false);
    const notify = useNotify();

    const handleDl = () => {
        setLoading(true);
        provider.downloadInvoice({issuerId, invoiceId})
            .then((response: Response) => {
                if (!response.ok) {
                    throw new Error('invalid response');
                }

                return response.blob();
            })
            .then((response: Blob) => {
                const link = document.createElement("a");
                link.target = "_blank";
                link.download = `${invoiceId}.xlsx`;
                link.href = URL.createObjectURL(
                    new Blob([response], { type: "application/vnd.ms-excel" })
                );
                link.click();

                // Clean up and remove the link
                link.remove();
            })
            .catch(err => notify(err.toString(), {type: 'error'}))
            .finally(() => setLoading(false))
    }

    return <Button onClick={handleDl} label={'Télécharger'} variant={'outlined'} disabled={loading}/>;
}

export default DownloadInvoiceButton;
