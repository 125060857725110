import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import dataProvider from '../../../providers/dataProvider';
import {useDataProvider, useNotify, useTranslate} from 'react-admin';
import { useEffect, useState } from 'react';
import { E55CCustomStepIcon } from './deployment/E55CCustomStepIcon';
import { DeployStats } from '../../../providers/dto/DeployStats';
import { ColorlibConnector } from './deployment/E55CCustomStepConnector';
import DashboardSection from './DashboardSection';
import { LocationStats } from '../../../providers/dto/LocationStats';
import Box from "@mui/material/Box";
/**
 *
 *  • Total PDC : Nombre total de points de charge en cours ou signés
    • Total PDC signé : Nombre total de points de charge avec AOT signé.
    • Total PDC signé avec prévision MES : Points de charge signés avec prévision de mis en service.
    • Total PDC signé sans prévision MES : Points de charge signés sans prévision de mise en service.
    Exemple : Grambois, Val Buech : Parkings en construction
    • Total PDC pas signé : Points de charge pour lesquels aucun contrat n'a été signé mais en pourparlers.
    • Total PDC pas signé avec prévision MES : Points de charge non signés mais qui ont une prévision de mise en service
    Exemple : Demande de racco anticipée : Le Cailar
    • Total PDC pas signé sans prévision MES : Points de charge non signés et sans prévision de mise en service.
 */


export default function DeploymentSection() {
  const[deploymentStats, setDeploymentStats] = useState<DeployStats>({} as DeployStats)
  const[locationStats, setLocationStats] = useState<LocationStats>({} as LocationStats)
  const provider = useDataProvider<typeof dataProvider>();
  const notify = useNotify();
  const translate = useTranslate();

  useEffect(() => {
    provider.getLocationStats()
    .then(loc => setLocationStats(loc))
    .catch((err: any) => notify(err.toString(), {type: 'error'}))
  }, [provider, notify])

  useEffect(() => {
    provider.getDeployStats()
    .then(stats => setDeploymentStats(stats))
    .catch((err: any) => notify(err.toString(), {type: 'error'}))
  }, [provider, notify])

  return (
    <DashboardSection title={translate('exploitation.stats.deployment.section')}>
        <Box sx={{display: "flex", justifyItems: "center", justifyContent: "center", paddingY: 5}}>
            <Stepper alternativeLabel activeStep={55} connector={<ColorlibConnector/>}>
                <Step key="signed">
                    <StepLabel StepIconComponent={(props) => <E55CCustomStepIcon {...props} value={deploymentStats.signedLeftToDeploy ?? '-'}/>}>{translate('exploitation.stats.deployment.signed')}</StepLabel>
                </Step>
                <Step key="evse">
                    <StepLabel StepIconComponent={(props) => <E55CCustomStepIcon {...props} value={locationStats.totalEVSE ?? '-'}/>}>{translate('exploitation.stats.deployment.deployed')}</StepLabel>
                </Step>
                <Step key="removed">
                    <StepLabel StepIconComponent={(props) => <E55CCustomStepIcon {...props} value={locationStats.totalRemoved ?? '-'}/>}>{translate('exploitation.stats.deployment.removed')}</StepLabel>
                </Step>
            </Stepper>
        </Box>
    </DashboardSection>
  );
}
