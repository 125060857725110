import {DateField, FunctionField, Show, SimpleShowLayout, TextField} from "react-admin";
import {TaskRequestMessage} from "../../../data/exploitation/taskrequestmessage";
import React from "react";

export default function TaskRequestShow() {
    return <Show<TaskRequestMessage>>
        <SimpleShowLayout>
            <DateField source="sentAt" sortable={true} showTime={true} locales={'fr-FR'}/>
            <FunctionField source="chargeboxes" sortable={true} render={(record) => record.chargeboxes[0]}/>
            <TextField source="taskType" sortable={true}/>
            <TextField source="key" sortable={true}/>
            <TextField source="value" sortable={true}/>
            <TextField source="connectorId" sortable={true}/>
        </SimpleShowLayout>
    </Show>
}
