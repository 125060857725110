import React from "react";
import Box from "@mui/material/Box";
import {
    ArrayField,
    Datagrid,
    FunctionField,
    SimpleShowLayout,
    TextField,
    useRecordContext,
    useTranslate
} from "react-admin";
import {TariffElement, TariffGroup} from "../../../../providers/dto/tariffGroup";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {Typography} from "@mui/material";
import Chip from "@mui/material/Chip";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TariffTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TariffElements({elements}: {elements: TariffElement[]}) {
    return <Box>{elements.map((element, index) => (
        <Box key={index}>
            <Typography variant="body1">Composants de prix</Typography>
            <ArrayField record={element} source="price_components">
                <Datagrid bulkActionButtons={false} rowClick={false}>
                    <TextField source="type" label={'resources.tariffgroups.fields.subtariffs.tariff.elements.price_components.type'}/>
                    <TextField source="price" label={'resources.tariffgroups.fields.subtariffs.tariff.elements.price_components.price'}/>
                    <TextField source="step_size" label={'resources.tariffgroups.fields.subtariffs.tariff.elements.price_components.step_size'}/>
                </Datagrid>
            </ArrayField>

            <Restrictions element={element} />
        </Box>))}
    </Box>;
}

function Restrictions({element}: any) {
    const translate = useTranslate();

    if (!element?.restrictions || Object.keys(element.restrictions).length === 0) {
        return null;
    }

    return (<><Typography variant={'body1'} sx={{paddingY: 2}}>{translate('resources.tariffgroups.fields.subtariffs.tariff.elements.restrictions.section')}</Typography>
    <SimpleShowLayout>
        <TextField record={element} source="restrictions.start_time" label={'resources.tariffgroups.fields.subtariffs.tariff.elements.restrictions.start_time'}/>
        <TextField record={element} source="restrictions.end_time" label={'resources.tariffgroups.fields.subtariffs.tariff.elements.restrictions.end_time'}/>
        <FunctionField record={element} source="restrictions.day_of_week"
                       label={'resources.tariffgroups.fields.subtariffs.tariff.elements.restrictions.day_of_week'}
                       render={(_) => <Box sx={{display: 'flex', gap:1 }}>
                           {element?.restrictions?.day_of_week?.map((item: any) => <Chip label={item} key={item}/>)}
                       </Box>}/>
    </SimpleShowLayout></>);
}

export default function TariffsTabs() {
    const record = useRecordContext<TariffGroup>();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    {record?.subtariffs?.map((subtariff, index) => (
                        <Tab key={subtariff.tariffkey} label={subtariff.tariff.id} {...a11yProps(index)} />
                    ))}
                </Tabs>
            </Box>
            {record?.subtariffs?.map((subtariff, index) => (
                <TariffTabPanel key={subtariff.tariffkey} value={value} index={index}>
                    <SimpleShowLayout>
                        <TextField record={subtariff} source="tariffkey" />
                        <TextField record={subtariff} source="tariff.id" />
                        <TextField record={subtariff} source="tariff.currency" />

                        <TariffElements elements={subtariff.tariff.elements} />
                    </SimpleShowLayout>
                </TariffTabPanel>
            ))}
        </Box>
    );
}
