import { atom, useRecoilState } from "recoil";
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { useDataProvider, useNotify, useTranslate } from "react-admin";
import dataProvider from "../../../../providers/dataProvider";
import { useEffect, useState } from "react";

export const cdrPartySelectState = atom<string>({
    key: "cdr-issuer-filter",
    default: '007'
})

const CdrPartySelection : React.FC = () => {
    const [cdrPartySelect, setCdrPartySelect] = useRecoilState(cdrPartySelectState);
    const [partyList, setPartyList] = useState<string[]>([])
    const provider = useDataProvider<typeof dataProvider>();
    const notify = useNotify();
    const translate = useTranslate();

    const updateSelectedIssuer = (event: SelectChangeEvent<string>) => {
        setCdrPartySelect(event.target.value)
    }

    useEffect(() => {
        provider.getPartyList()
        .then(
            (resp) => {
                setPartyList(resp)
            }
        ).catch((err: any) => notify(err.toString(), {type: 'error'}))
    }, [notify, provider])


    return(
        <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
            <InputLabel id="cdr-party-label">{translate("exploitation.stats.cdrs.partySelect")}</InputLabel>
            <Select
                labelId="cdr-party-label"
                value={cdrPartySelect}
                label={translate("exploitation.stats.cdrs.partySelect")}
                onChange={updateSelectedIssuer}
            >
                {
                    partyList.map((e, i) => (
                        <MenuItem value={e}>{e}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>

    );
}

export default CdrPartySelection;
