import {Yess55Theme} from "./yes55/theme";
import {E55cTheme} from "./e55c/theme";
import {GcaTheme} from "./gca/theme";
import appConfig from "../../config";
import {createTheme} from "@mui/material/styles";

const tenant = appConfig.TENANT;

let appTheme = E55cTheme;

switch (tenant) {
    case 'yes55':
        appTheme = Yess55Theme;
        break;
    case 'gca':
        appTheme = GcaTheme;
        break;
}

appTheme = {
    light: createTheme(appTheme.light, {
        components: {
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        background: '#ffffff',
                        color: '#000000',
                    },
                },
            },
            RaMenuItemLink: {
                styleOverrides: {
                    root: {
                        // invisible border when not active, to avoid position flashs
                        borderLeft: '3px solid transparent',
                        '&.RaMenuItemLink-active': {
                            borderLeft: `3px solid`,
                            // @ts-ignore
                            borderColor: appTheme.palette?.primary.main
                        },
                    },
                },
            },
        }
    }),
    dark: createTheme(appTheme.dark, {
        components: {
            // MuiAppBar: {
            //     styleOverrides: {
            //         root: {
            //             background: '#ffffff',
            //             color: '#000000',
            //         },
            //     },
            // },
            RaMenuItemLink: {
                styleOverrides: {
                    root: {
                        // invisible border when not active, to avoid position flashs
                        borderLeft: '3px solid transparent',
                        '&.RaMenuItemLink-active': {
                            borderLeft: `3px solid`,
                            // @ts-ignore
                            borderColor: appTheme.palette?.primary.main
                        },
                    },
                },
            },
        }
    }),
};

export default appTheme;
