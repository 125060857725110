import { defaultTheme } from 'react-admin';
import {createTheme} from "@mui/material/styles";

export const GcaTheme = {
    light: {
        ...defaultTheme,
        ...createTheme({palette: {
                primary: {
                    main: '#485158'
                },
                secondary: {
                    main: '#ef2035'
                },
            }
        }),
    },
    dark: {
        ...defaultTheme,
        ...createTheme({palette: {
                mode: 'dark',
                primary: {
                    main: '#485158'
                },
                secondary: {
                    main: '#ef2035'
                },
            }
        }),
    }
};
