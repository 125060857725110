import {SelectInput} from "react-admin";

const CurrencyInput = ({choices, ...rest}: any) => {
    return <SelectInput choices={[
        { id: 'EUR', name: 'EUR' },
    ]} {...rest}>
    </SelectInput>
}

export default CurrencyInput;
