import {Logout, useDataProvider, UserMenu} from "react-admin";
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import VersionIcon from '@mui/icons-material/Info';
import {useEffect, useState} from "react";
import Config from "../../config";

const VersionMenuItem = (props: any) => {
    const provider = useDataProvider();
    const [version, setVersion] = useState('');

    useEffect(() => {
        provider.getApiVersion().then((response: any) => {
            setVersion(response);
        });
    }, [provider]);

    // We are not using MenuItemLink so we retrieve the onClose function from the UserContext
    return (
        <MenuItem
            {...props}
        >
            <ListItemIcon sx={{alignItems: "start"}}>
                <VersionIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
                UX v{Config.APP_VERSION}<br/>
                API v{version}
            </ListItemText>
        </MenuItem>
    );
}

export default function AppUserMenu() {
    return <UserMenu>
        <Logout />
        <VersionMenuItem />
    </UserMenu>;
}
