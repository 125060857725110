import {LineChart, CartesianGrid, XAxis, YAxis, Line, Tooltip} from "recharts";
import Box from "@mui/material/Box";
import { CdrStatisticsOverTime } from "../../../../providers/dto/CdrStats";
import {Typography} from "@mui/material";
import {toEuro} from "../../../../helpers/helper";
import { useContext } from "react";
import AngusPaletteContext from "../../../components/provider/AngusPaletteProvider";
import { useTranslate } from "react-admin";

interface CdrLineChartProps {
    sessionStatsOverTime: CdrStatisticsOverTime[] | undefined
}

export const CdrLineChart: React.FC<CdrLineChartProps> = ({sessionStatsOverTime}) => {  
    const translate = useTranslate();

    return (
        <Box sx={{display: {sm: "flex"}, paddingY: 3}}>
            <DisplayLineChart title={translate("exploitation.stats.cdrs.chartCost")} dataKey={"cost_data"} sessionStatsOverTime={sessionStatsOverTime} isEuroValue={true}/>
            <DisplayLineChart title={translate("exploitation.stats.cdrs.chartSession")} dataKey={"totalSession"} sessionStatsOverTime={sessionStatsOverTime} />
        </Box>
    );
}

type DisplayLineChartParams = {
    title: string,
    dataKey: string,
    sessionStatsOverTime: CdrStatisticsOverTime[] | undefined,
    isEuroValue?: boolean
}

const valueDayFormatter = (val: number) => {
    return (val >= 0 ? 'J-':'J') + val;
}

function DisplayLineChart({title, dataKey, sessionStatsOverTime, isEuroValue = false}: DisplayLineChartParams) {
    const {palette} = useContext(AngusPaletteContext)
    return (
        <Box>
            <Box><Typography variant={'h6'} sx={{textAlign: "center"}}>{title}</Typography></Box>
            {(sessionStatsOverTime?.length ?? 0) > 0 ? 
                <LineChart width={400} height={350}>
                    <Tooltip formatter={(value: number) => isEuroValue ? toEuro(value) : value} labelFormatter={valueDayFormatter}/>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis  dataKey="date_diff" allowDuplicatedCategory={false} tickFormatter={valueDayFormatter}/>
                    <YAxis  allowDataOverflow={true} />
                    {
                        sessionStatsOverTime?.map((el, index) => (
                            <Line dataKey={dataKey} data={el.data} name={el._id} key={el._id} stroke={palette.getColorForName(el._id)}/>
                        ))
                    }
                </LineChart>
            : <Typography variant={'body1'} sx={{textAlign: "center"}}>Pas de données</Typography>}
        </Box>
    );
}
