import CardWithIcon from "./CardWithIcon";
import DashboardSection from "./DashboardSection";
import {EVSE, EVSEAvailable, EVSEPlanned, EVSERemoved, LocationIcon} from "../../constants";
import { useEffect, useState } from "react";
import {useDataProvider, useNotify, useTranslate} from "react-admin";
import dataProvider from "../../../providers/dataProvider";
import { LocationStats } from "../../../providers/dto/LocationStats";
import Box from "@mui/material/Box";

export default function LocationsSection() {
    const[locationStats, setLocationStats] = useState<LocationStats>();
    const provider = useDataProvider<typeof dataProvider>();
    const notify = useNotify();
    const translate = useTranslate();

    const sxCards = {
        width: {
            xs: '100%',
            md: 250,
        }
    };

    useEffect(() => {
        provider.getLocationStats()
        .then((resp) => setLocationStats(resp))
        .catch((err: any) => notify(err.toString(), {type: 'error'}))
    }, [provider, notify])

    return(
        <DashboardSection title={translate('exploitation.stats.locations.section')}>
            <Box sx={{display: "flex", flexDirection: {xs: "column", sm: "row"}, gap: 2, flexWrap: "wrap", width: "inherit"}}>
                <CardWithIcon icon={LocationIcon} to="#" title={translate('exploitation.stats.locations.totalLocation')} subtitle={locationStats?.totalLocation ?? '0'} sx={sxCards}/>
                <CardWithIcon icon={EVSE} to="#" title={translate('exploitation.stats.locations.totalEVSE')} subtitle={locationStats?.totalEVSE ?? '0'} sx={sxCards}/>
                <CardWithIcon icon={EVSEPlanned} to="#" title={translate('exploitation.stats.locations.totalPlanned')} subtitle={locationStats?.totalPlanned ?? '0'} sx={sxCards}/>
                <CardWithIcon icon={EVSEAvailable} to="#" title={translate('exploitation.stats.locations.totalAvailable')} subtitle={locationStats?.totalAvailable ?? '0'} sx={sxCards}/>
                <CardWithIcon icon={EVSERemoved} to="#" title={translate('exploitation.stats.locations.totalRemoved')} subtitle={locationStats?.totalRemoved ?? '0'} sx={sxCards}/>
            </Box>
        </DashboardSection>
    );
}
