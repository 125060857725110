import polyglotI18nProvider from 'ra-i18n-polyglot';
import fr from 'ra-language-french';
import frMessages from "./i18n/fr";

const messages = {
    ...fr,
    ...frMessages
}

export const i18nProvider = polyglotI18nProvider(() => messages, 'fr');
