import {Menu} from "react-admin";
import {AppPermissions} from "../data/constants/permissions";
import appConfig from "../../config";
import Submenu from "./Submenu";
import {
    CdrIcon, DeployIcon,
    ExploitationIcon,
    FacturationIcon,
    LocationIcon,
    QueryStatsIcon,
    TariffsIcon
} from "../constants";
import InvoicingIcon from "@mui/icons-material/Euro";
import SettingsIcon from "@mui/icons-material/Settings";
import ExploitationAdminPage from "../pages/exploitation/ExploitationAdminPage";
import SettingsPage from "../pages/settings/SettingsPage";
import {useIsAllowed} from "../../helpers/useIsAllowed";

const tariffsEnabled = appConfig.TARIFFS_ENABLED;
const xlsxEnabled = appConfig.XLSX_ENABLED;

export default function AppMenu() {
    const { isAllowed, isAllowedAny } = useIsAllowed();
    const showMenuTariffs = tariffsEnabled &&
        isAllowedAny([
            AppPermissions.TariffLinksView,
            AppPermissions.TariffLinksManage,
            AppPermissions.TariffGroupsView,
            AppPermissions.TariffGroupsManage
        ]);
    const showMenuExploitation = isAllowedAny([
        AppPermissions.CdrsView,
        AppPermissions.CdrsSync,
        AppPermissions.ExploitationLocationsView,
        AppPermissions.ExploitationLocationsManage,
        AppPermissions.ExploitationLocationsOpenDataView,
        AppPermissions.ExploitationLocationsOpenDataEdit,
        AppPermissions.ExploitationManage
    ]);
    const showMenuFacturation = appConfig.FEATURE_SHOW_INVOICING;
    const showMenuStatistics = appConfig.FEATURE_SHOW_STATS;
    const showMenuDeployStatistics: boolean = xlsxEnabled;

    return <Menu>
        <Menu.DashboardItem primaryText={'Tableau de bord'}/>
        {showMenuTariffs &&
            <Submenu text={'menu.items.tariffs.label'} icon={<TariffsIcon />}>
                {tariffsEnabled && isAllowed(AppPermissions.TariffGroupsView) ? <Menu.ResourceItem name="tariffgroups" /> : <></>}
                {tariffsEnabled && isAllowed(AppPermissions.TariffLinksView) ? <Menu.ResourceItem name="tarifflinks" /> : <></>}
            </Submenu>}
        {showMenuExploitation &&
            <Submenu text={'menu.items.exploitation.label'} icon={<ExploitationIcon />}>
                {isAllowed(AppPermissions.CdrsView) && <Menu.ResourceItem name="cdrs" />}
                {isAllowed(AppPermissions.CdrsSync) && <Menu.ResourceItem name="cdr-import-jobs" />}
                {isAllowedAny([
                    AppPermissions.ExploitationLocationsView,
                    AppPermissions.ExploitationLocationsManage,
                    AppPermissions.ExploitationLocationsOpenDataView,
                    AppPermissions.ExploitationLocationsOpenDataEdit,
                ]) && <Menu.ResourceItem name="exploitation-locations" />}
                {isAllowed(AppPermissions.SuperAdmin) && <Menu.ResourceItem name="chargeboxes" />}
                {appConfig.FEATURE_EXPLOITATION_STATS_MAILING && isAllowed(AppPermissions.ExploitationManage) && <Menu.Item to={ExploitationAdminPage.route} primaryText={'menu.items.exploitation.submenu.admin'} leftIcon={<SettingsIcon/>}/>}
            </Submenu>}
        {showMenuFacturation && isAllowed(AppPermissions.InvoicingAccess) &&
            <Submenu text={'menu.items.facturation.label'} icon={<FacturationIcon />}>
                {<Menu.ResourceItem name="invoicingIssuers" />}
            </Submenu>}
        {showMenuStatistics &&
            <Submenu text={'menu.items.statistics.label'} icon={<QueryStatsIcon />}>
                {<Menu.Item to={'statistics/locations'} primaryText={'menu.items.statistics.submenu.locations'} leftIcon={<LocationIcon/>}/>}
                {<Menu.Item to={'statistics/cdrs'} primaryText={'menu.items.statistics.submenu.cdrs'} leftIcon={<CdrIcon/>}/>}
                {showMenuDeployStatistics && <Menu.Item to={'statistics/deployment'} primaryText={'menu.items.statistics.submenu.deployment'} leftIcon={<DeployIcon/>}/>}
                {isAllowed(AppPermissions.CdrsView) && <Menu.Item to={'statistics/invoicing'} primaryText={'menu.items.statistics.submenu.invoicing'} leftIcon={<InvoicingIcon/>}/>}
            </Submenu>
        }
        {isAllowed(AppPermissions.ConfigManage) && <Menu.Item to={SettingsPage.route} primaryText="menu.items.settings" leftIcon={<SettingsIcon />} />}
    </Menu>
}
