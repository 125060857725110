import React from "react";
import {
    ArrayInput,
    FormDataConsumer,
    NumberInput,
    required, SelectArrayInput,
    SelectInput,
    SimpleFormIterator,
    TextInput, TimeInput, useRecordContext,
    useTranslate
} from "react-admin";
import TariffKey from "../../components/input/TariffKey";
import CurrencyInput from "../../components/input/CurrencyInput";
import { alpha } from '@mui/material';
import {colors} from "../../constants";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Stack } from '@mui/material';
import Typography from "@mui/material/Typography";
import {AddRowButton} from "../../components/input/AddRowButton";
import {RemoveRowButton} from "../../components/input/RemoveRowButton";

export const Tarifffields = () => {
    const translate = useTranslate();
    const record = useRecordContext();

    return <>
        <TextInput source="tariffgroup_id" validate={required()} readOnly={!!record?.id}/>
        <Typography variant="h6">{translate('resources.tariffgroups.labels.subtariffs')}</Typography>
        <SubTariffsInput />
    </>
}

const validateUniqueTariffKey = (formData: any) => (value: any) => {
    const usedTariffKey : string[] = [];

    for (let subtariff of formData.subtariffs ?? []) {

        if (usedTariffKey.includes(subtariff.tariffkey)) {
            return 'resources.tariffgroups.validation.tariffkey_unique';
        }

        usedTariffKey.push(subtariff.tariffkey);
    }

    return undefined;
};

const SubTariffsInput = () => {
    const translate = useTranslate();

    return (<ArrayInput source="subtariffs" name={"subtariffs"} label={false}>
        <SimpleFormIterator
            addButton={<AddRowButton variant={'outlined'} label={'resources.tariffgroups.cta.add_subtariff'}></AddRowButton>}
            removeButton={<RemoveRowButton label={'resources.tariffgroups.cta.remove_subtariff'} />}
            disableClear
        >
            <FormDataConsumer>
                {({
                      formData, scopedFormData, // The data for this item of the ArrayInput
                      })=> {
                        return <Accordion sx={{marginY: 1}}>
                            <AccordionSummary expandIcon={<ArrowDownwardIcon/>}
                                              aria-controls="panel1-content"
                                              id="panel1-header">{scopedFormData?.tariff?.id ?? 'Nouveau'}</AccordionSummary>
                            <AccordionDetails>
                                <Stack alignItems="flex-start">
                                    <TariffKey source={"tariffkey"} validate={validateUniqueTariffKey(formData)}/>
                                    <TextInput source={"tariff.id"} validate={required()}/>
                                    <CurrencyInput source={"tariff.currency"} validate={required()} defaultValue={"EUR"}/>
                                    <Typography variant="h6">{translate('resources.tariffgroups.labels.elements')}</Typography>
                                    <TariffElements source={"tariff.elements"} />
                                </Stack>
                            </AccordionDetails>
                        </Accordion>;
                    }
                }
            </FormDataConsumer>
        </SimpleFormIterator>
    </ArrayInput>);
}

const TariffElements = ({source, disableAdd = false} : {source: string, disableAdd?: boolean}) => {
    const translate = useTranslate();

    return (<ArrayInput source={source} label={false}>
        <SimpleFormIterator
            disableAdd={disableAdd}
            addButton={<AddRowButton variant={'outlined'} label={'resources.tariffgroups.cta.add_element'} />}
            removeButton={<RemoveRowButton label={'resources.tariffgroups.cta.remove_element'} />}
            disableClear
            sx={{
                '.RaSimpleFormIterator-line': {
                    paddingLeft: '8px',
                    marginY: '8px',
                    backgroundColor: alpha(colors.e55cLightBlue, 0.1)
                }
            }}>
            <PriceComponentsInput source="price_components" />

            <Typography variant={'h6'} sx={{paddingBottom: 2}}>{translate('resources.tariffgroups.fields.subtariffs.tariff.elements.restrictions.section')}</Typography>
            <FormDataConsumer>
                {({scopedFormData}) => {
                    return <>
                        <TimeInput source="restrictions.start_time"
                               format={(val) => {
                                   // Workaround for the fact otherwise it returns full date...
                                   return val || undefined
                               }}
                               parse={(val) => val || undefined}
                               sx={{ maxWidth: 150 }}
                               validate={(value) => {
                                  if (!value && scopedFormData?.restrictions?.end_time) {
                                    return 'resources.tariffgroups.validation.start_end_time_inconsistency';
                                  }
                               }}
                        />
                        <TimeInput source="restrictions.end_time"
                                   format={(val) => {
                                       // Workaround for the fact otherwise it returns full date...
                                       return val || undefined
                                   }}
                                   parse={(val) => val || undefined}
                                   sx={{ maxWidth: 150 }}
                                   validate={(value) => {
                                       if (!value && scopedFormData?.restrictions?.start_time) {
                                           return 'resources.tariffgroups.validation.start_end_time_inconsistency';
                                       }
                                   }}
                        />
                        <SelectArrayInput source="restrictions.day_of_week"
                          choices={[
                              {id: "MONDAY", name: "MONDAY"},
                              {id: "TUESDAY", name: "TUESDAY"},
                              {id: "WEDNESDAY", name: "WEDNESDAY"},
                              {id: "THURSDAY", name: "THURSDAY"},
                              {id: "FRIDAY", name: "FRIDAY"},
                              {id: "SATURDAY", name: "SATURDAY"},
                              {id: "SUNDAY", name: "SUNDAY"}
                          ]}/>
                    </>}}
            </FormDataConsumer>
        </SimpleFormIterator>
    </ArrayInput>);
}


const PriceComponentsInput = ({source} : {source: string}) => {
    return (<ArrayInput source={source} label={false} sx={{paddingTop: 2}} fullWidth={true}>
        <SimpleFormIterator inline
                            addButton={<AddRowButton label={'resources.tariffgroups.cta.add_price_component'}></AddRowButton>}
                            removeButton={<RemoveRowButton label={'resources.tariffgroups.cta.remove_price_component'} />}
                            disableClear
                            fullWidth={true}
                            sx={{
                                '.RaSimpleFormIterator-line': {
                                    paddingLeft: '8px',
                                    backgroundColor: alpha(colors.e55cLightBlue, 0.1)
                                }
                            }}>
            <NumberInput source="price" validate={required()} min={0} />
            <NumberInput source="step_size" validate={required()} step={1} min={0} />
            <SelectInput source="type" validate={required()} choices={[{id: "TIME", name: "TIME"}, {id: "PARKING_TIME", name: "PARKING_TIME"}, {id: "ENERGY", name: "ENERGY"}, {id: "FLAT", name: "FLAT"}]} sx={{marginTop: 0}}/>
        </SimpleFormIterator>
    </ArrayInput>)
}
