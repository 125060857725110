import {Evse, Location} from "../../../data/exploitation/location";
import jsonExport from "jsonexport/dist";
import {downloadCSV} from "react-admin";
import {countries3to2} from "../../../../helpers/countries";

export default function exporter(records: Location[]) {
    const rows :any[] = [];

    records.forEach((location) => {
        location.evses?.forEach((evse: Evse) => {
            evse.connectors?.forEach((connector) => {
                rows.push({
                    location: {
                        name: location.name,
                        id: location.id,
                        country: location.country ? countries3to2[location.country] ?? '' : '',
                        city: location.city,
                        postal_code: location.postal_code,
                        address: location.address,
                        latitude: location.coordinates?.latitude,
                        longitude: location.coordinates?.longitude,
                    },
                    evse: {
                        id: evse.evse_id,
                        status: evse.status,
                    },
                    id: connector.id,
                    standard: connector.standard,
                    format: connector.format,
                    power_type: connector.power_type,
                    voltage: connector.voltage,
                    amperage: connector.amperage,
                });
            })
        });
    });

    jsonExport(
        rows,
        {
            fillGaps: true,
            rowDelimiter: ';',
            // mapHeaders: (header: string) : string => {
            //     return header;
            // },
            headers: [
                'location.name',
                'location.id',
                'location.country',
                'location.city',
                'location.postal_code',
                'location.address',
                // 'house_number',
                'location.latitude',
                'location.longitude',
                'evse.id',
                'id',
                'evse.status',
                'standard',
                'format',
                'power_type',
                'voltage',
                'amperage',
            ]
        },
        (err: any, csv: any) => {
            downloadCSV(csv, 'locations'); // download as 'posts.csv` file
        });
};
