import {Datagrid, DateField, List, SimpleShowLayout, TextField, useRecordContext} from "react-admin";
import {EvseMessage} from "../../../data/exploitation/evsemessage";
import ChargeboxesSubMenuBar from "../components/ChargeboxesSubMenuBar";

export default function EvseMessageList() {
    return <>
        <ChargeboxesSubMenuBar/>
        <List<EvseMessage> sort={{field: "receivedAt", order: "DESC"}}>
        <Datagrid rowClick="false" bulkActionButtons={false} expand={<ExpandReceivedMessage/>}>
            <DateField source="receivedAt" sortable={true} showTime={true} locales={'fr-FR'}/>
            <TextField source="chargeboxId" sortable={true} />
            <TextField source="type" sortable={true}/>
        </Datagrid>
    </List>
    </>
}

function ExpandReceivedMessage() {
    const record = useRecordContext();

    if (!record) {
        return null;
    }

    switch (record.type) {
        case 'BootNotification':
            return <ExpandBootNotification record={record}/>;
        case 'StatusNotification':
            return <ExpandStatusNotification record={record}/>;
    }

    return null;
}

function ExpandBootNotification({ record }: { record: any }) {
    if (!record) {
        return null;
    }

    return <SimpleShowLayout>
        {record.ocppBootNotification && Object.keys(record.ocppBootNotification).map((key) => {
            return <TextField key={key} source={`ocppBootNotification.${key}`}/>
        })}
    </SimpleShowLayout>;
}

function ExpandStatusNotification({ record }: { record: any }) {
    if (!record) {
        return null;
    }

    return <SimpleShowLayout>
        {record.ocppStatusNotification && Object.keys(record.ocppStatusNotification).map((key) => {
            return <TextField key={key} source={`ocppStatusNotification.${key}`}/>
        })}
    </SimpleShowLayout>;
}
