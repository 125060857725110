export enum AppPermissions {
    CdrsView = "cdrs:view",
    CdrsEdit = "cdrs:edit",
    CdrsSync = "cdrs:sync",
    CdrOverwriteCost = "cdrs:overwrite-cost",
    TariffGroupsView = "tariffgroups:view",
    TariffGroupsManage = "tariffgroups:manage",
    TariffLinksView = "tarifflinks:view",
    TariffLinksManage = "tarifflinks:manage",
    ExploitationManage = "exploitation:manage",
    ExploitationLocationsView = "exploitation:locations:view",
    ExploitationLocationsManage = "exploitation:locations:manage",
    ExploitationLocationsOpenDataView = "exploitation:locations:opendata:view",
    ExploitationLocationsOpenDataEdit = "exploitation:locations:opendata:edit",
    InvoicingAccess = "invoicing:access",
    ConfigManage = "config:manage",
    SuperAdmin = "superadmin",
}
