export const colors = {
    e55cLightBlue: '#66a7e5'
}

export { default as CdrIcon } from '@mui/icons-material/Receipt';
export { default as CdrsImportIcon } from '@mui/icons-material/Sync';
export { default as LocationIcon } from "@mui/icons-material/EvStation";
export { default as EVSE} from '@mui/icons-material/ElectricalServices'
export { default as EVSEPlanned} from '@mui/icons-material/Build'
export { default as EVSEAvailable } from '@mui/icons-material/FlashOn'
export { default as EVSERemoved } from '@mui/icons-material/FlashOff'
export { default as TariffgroupIcon } from "@mui/icons-material/EuroSymbol";
export { default as TarifflinkIcon } from "@mui/icons-material/Link";
export { default as IssuerIcon } from "@mui/icons-material/Business";
export { default as TariffsIcon } from "@mui/icons-material/EuroSymbol";
export { default as ExploitationIcon } from '@mui/icons-material/Work';
export { default as FacturationIcon } from '@mui/icons-material/Receipt';
export { default as QueryStatsIcon } from '@mui/icons-material/QueryStats';
export { default as PlaceIcon } from '@mui/icons-material/Place';
export { default as StoreIcon } from '@mui/icons-material/Store';
export { default as AttachMoneyIcon } from '@mui/icons-material/AttachMoney';
export { default as GroupIcon} from '@mui/icons-material/Group';
export { default as LocalAtmIcon} from '@mui/icons-material/LocalAtm';
export { default as DeployIcon } from '@mui/icons-material/Engineering';
export { default as TaskRequestMessagesIcon } from '@mui/icons-material/Mail';
