import Box from "@mui/material/Box";
import { CdrStatistics } from "../../../../providers/dto/CdrStats";
import E55CPieChart from "../common/E55CPieChart";
import { useTranslate } from "react-admin";
import E55CChartLegend from "../common/E55CChartLegend";
import {Alert} from "@mui/material";

interface E55CPieChartProps {
    sessionStats: CdrStatistics[] | undefined
}

const CdrPieChart: React.FC<E55CPieChartProps> = ({sessionStats}) => {
    const translate = useTranslate();

    if (!sessionStats || sessionStats.length === 0) {
        return <Alert severity="info">{translate("exploitation.stats.cdrs.noData")}</Alert>
    }

    return (
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", paddingY: 3}}>
            <Box sx={{display: {sm: "flex"}}}>
                <E55CPieChart title={translate("exploitation.stats.cdrs.chartCost")} dataKey="cost_data" sessionStats={sessionStats} isEuroValue={true}></E55CPieChart>
                <E55CPieChart title={translate("exploitation.stats.cdrs.chartSession")} dataKey="totalSession" sessionStats={sessionStats}></E55CPieChart>
            </Box>
            <E55CChartLegend sessionStats={sessionStats}></E55CChartLegend>
        </Box>
    );
}

export default CdrPieChart;
