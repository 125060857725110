import { CdrStatistics } from "../../../../providers/dto/CdrStats";
import { getLegendColorElement } from "./color-react-helper";
import { useContext } from "react";
import AngusPaletteContext from "../../../components/provider/AngusPaletteProvider";
import Box from "@mui/material/Box";

// TODO : unduplicate
interface E55CPieChartProps {
    sessionStats: CdrStatistics[] | undefined
}

const E55CChartLegend: React.FC<E55CPieChartProps> = ({sessionStats}) => {
    const {palette} = useContext(AngusPaletteContext)

    return (<Box sx={{display: "flex", flexWrap: "wrap"}}>
        {sessionStats?.map((stat, index) => (
            <Box>{ getLegendColorElement(palette.getColorForName(stat.name)) } {stat.name}</Box>
        ))}
    </Box>);
}

export default E55CChartLegend;
