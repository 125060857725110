import { CdrStatistics } from "../../../../providers/dto/CdrStats";
import { Cell, Pie, PieChart, Tooltip } from "recharts";
import Box from "@mui/material/Box";

import {Typography} from "@mui/material";
import {toEuro} from "../../../../helpers/helper";
import { useContext } from "react";
import AngusPaletteContext from "../../../components/provider/AngusPaletteProvider";
import { useTranslate } from "react-admin";

type DisplayPieChartParams = {
    title: string,
    dataKey: string,
    sessionStats: CdrStatistics[] | undefined,
    isEuroValue?: boolean
}

function E55CPieChart({title, dataKey, sessionStats, isEuroValue = false} : DisplayPieChartParams) : JSX.Element {
    
    const {palette} = useContext(AngusPaletteContext)
    const translate = useTranslate()

    return (
        <Box>
            <Box><Typography variant={'h6'} sx={{textAlign: "center"}}>{title}</Typography></Box>
            {sessionStats?.length ? <PieChart width={350} height={350}>
                <Tooltip formatter={(value: number) => isEuroValue ? toEuro(value) : value}/>
                {
                    <Pie data={sessionStats} dataKey={dataKey} nameKey="name" cornerRadius={5} innerRadius={10}
                    cx="50%" cy="50%" fill="#8884D8">
                        {
                            sessionStats?.map((entry, index) => (
                                <Cell key={dataKey} fill={palette.getColorForName(entry.name)}/>
                        ))}
                    </Pie>
                }
            </PieChart> : <Typography variant={'body1'} sx={{textAlign: "center", p: 3}}>{translate("exploitation.stats.noData")}</Typography>}
        </Box>
    );
}

export default E55CPieChart;