import {
    Button,
    useNotify,
    useDataProvider,
    DateTimeInput,
    SimpleForm,
    Toolbar,
    SaveButton,
    LinearProgress, useRedirect, ReferenceArrayInput, AutocompleteArrayInput, required, useRefresh
} from "react-admin";
import * as React from "react";
import {useCallback, useState} from "react";
import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';

export const ButtonTriggerSync = () => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const provider = useDataProvider();

    const translate = useCallback((str: string) => str, []);

    const handleClose = () => setOpen(false);

    const handleSubmit = (data: any) => {
        setLoading(true);
        provider.addSyncjob(data)
            .then((json: any) => {
                notify(`Nouvelle synchronisation programmée`);
                redirect('list', 'cdr-import-jobs');
                refresh();
            })
            .catch((e: any) => {
                if (e.message) {
                    notify(`Erreur : ${e.message}`, {type: "error"});
                }
                else {
                    notify('Erreur de création de tâche de synchronisation', {type: "error"});
                }
            })
            .finally(() => setLoading(false));
    }

    return <>
        <Button onClick={() => setOpen(true)} label={translate('Synchronisation')} variant={'outlined'} data-testid="triggerSyncButton" />
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{translate('Programmer une synchronisation')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {translate('Choisir des dates de début et de fin')}
                </DialogContentText>
                <SimpleForm onSubmit={handleSubmit} toolbar={<FormToolbar loading={loading}/>} data-testid="syncForm">
                    <ReferenceArrayInput name="emsps" source="emsps" reference="exploitationEmsps">
                        <AutocompleteArrayInput label="Emsp" />
                    </ReferenceArrayInput>
                    <DateTimeInput source="dateFrom" validate={required()} name={'dateFrom'} data-testid="dateFromInput"/>
                    <DateTimeInput source="dateTo" validate={required()} name={'dateTo'} data-testid="dateToInput"/>
                </SimpleForm>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} label={translate('Annuler')} />
            </DialogActions>
        </Dialog>
    </>
}

const FormToolbar = ({loading}: {loading: boolean}) => {
    return <Toolbar sx={{justifyContent: "center"}}>
        {loading ? <LinearProgress/> : <SaveButton label={'Démarrer'} icon={<SyncIcon/>} data-testid="startSyncButton"/>}
    </Toolbar>
}
