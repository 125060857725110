import {Typography} from "@mui/material";
import * as React from "react";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";

export default function DashboardSection({title, children}: { title: string, children: React.ReactNode }) {
    return (
        <Card sx={{marginY: 2}}>
            <CardContent title={title}>
                <Typography variant={'h4'} marginBottom={2}>{title}</Typography>
                {children}
            </CardContent>
        </Card>);
}
