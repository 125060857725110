import {SelectInput} from "react-admin";
import appConfig from "../../../config";

const TariffKey = ({choices, ...rest}: any) => {
    const tariffsKeysSettings = appConfig.TARIFF_KEYS;
    const options = tariffsKeysSettings ? JSON.parse(tariffsKeysSettings) : ['AC_22KW', 'DC_50KW'];

    return <SelectInput
        choices={options.map((val: string) => ({ id: val, name: val }))}
        {...rest}>
    </SelectInput>
}

export default TariffKey;
