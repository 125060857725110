import { defaultTheme } from 'react-admin';
import { createTheme } from '@mui/material/styles';


export const E55cTheme = {
    light: {
        ...defaultTheme,
        ...createTheme({palette: {
                primary: {
                    main: '#005db7'
                },
            }
        }),
    },
    dark: {
        ...defaultTheme,
        ...createTheme({palette: {
                mode: 'dark',
                primary: {
                    main: '#005db7'
                },
            }
        }),
    }
};
