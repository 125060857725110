import {ListButton} from "react-admin";
import Box from "@mui/material/Box";

export default function ChargeboxesSubMenuBar() {
    return <Box sx={{display: "flex", justifyContent: "end", gap: 1, padding: 1}}>
        <ListButton resource="chargeboxes" label={'resources.chargeboxes.goToListCta'} variant={'contained'} color={'primary'}/>
        <ListButton resource="taskrequestmessages" label={'resources.taskrequestmessages.goToListCta'} variant={'contained'} color={'secondary'}/>
        <ListButton resource="evsemessages" label={'resources.evsemessages.goToListCta'} variant={'contained'} color={'success'}/>
    </Box>
}
