import SearchIcon from '@mui/icons-material/Search';
import {fetchUtils, InputProps, TextInput, useNotify, useRecordContext} from "react-admin";
import CircularProgress from '@mui/material/CircularProgress';

import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import {useCallback, useEffect, useState} from "react";
import {useFormContext} from "react-hook-form";

const CodeInseeInput = (props : InputProps) => {
    const record = useRecordContext();
    const [searching, setSearching] = useState(false);
    const notify = useNotify();
    const {setValue} = useFormContext();

    const fetchCode = useCallback(() => {
        if (!record || !record.address || !record.postal_code) {
            return;
        }

        setSearching(true);
        const url = new URL("https://api-adresse.data.gouv.fr/search/");
        url.searchParams.set('q', record.address);
        url.searchParams.set('postcode', record.postal_code);

        fetchUtils.fetchJson(url)
            .then(response => response.json)
            .then(response => {
                if (!response?.features[0]?.properties.citycode) {
                    notify('location.opendata.field.code_insee_commune.not_found');
                    return;
                }

                setValue(props.source, response.features[0].properties.citycode);
            })
            .finally(() => setSearching(false));
    }, [props.source, record, setValue, notify]);

    useEffect(() => {
        if (!record || record.openData?.code_insee) {
            return;
        }

        fetchCode()
    }, [record, fetchCode]);


    if (searching) {
        return <CircularProgress />;
    }

    return (<TextInput {...props} parse={value => value}
                       InputProps={{
                           endAdornment: <InputAdornment position="end">
                                   <IconButton
                                       aria-label="toggle password visibility"
                                       onClick={() => fetchCode()}
                                       edge="end"
                                   >
                                       <SearchIcon />
                                   </IconButton>
                           </InputAdornment>
                       }}/>);
}

export default CodeInseeInput;
