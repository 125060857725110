import * as React from 'react';
import clsx from 'clsx';
import {ButtonProps, useSimpleFormIterator, useSimpleFormIteratorItem, useTranslate} from "react-admin";
import Button from "@mui/material/Button";

export const RemoveRowButton = (props: Omit<ButtonProps, 'onClick'>) => {
    const { remove, index } = useSimpleFormIteratorItem();
    const { source } = useSimpleFormIterator();
    const { className, ...rest } = props;
    const translate = useTranslate();

    return (
        <Button
            label="ra.action.remove"
            size="small"
            onClick={() => remove()}
            color="warning"
            variant={'outlined'}
            className={clsx(
                `button-remove button-remove-${source}-${index}`,
                className
            )}
            {...rest}
        >
            {translate(props.label ?? 'ra.action.remove')}
        </Button>
    );
};
