import {  useContext, useEffect, useState } from "react";
import { useDataProvider, useNotify } from "react-admin";
import dataProvider from "../../../../providers/dataProvider";
import { CdrStatistics, CdrStatisticsOverTime } from "../../../../providers/dto/CdrStats";
import CdrPieChart from "./CdrPieChart";
import { CdrLineChart } from "./CdrLineChart";
import Box from "@mui/material/Box";
import { RemoteContentDisplay } from "../../../components/layout/RemoteContentDisplay";
import { useRecoilState } from "recoil";
import { dateRangePickerState } from "../DateRangePicker";
import { cdrDashboardTypeState } from "./CdrDashboardToggleGroup";
import { cdrPartySelectState } from "./CdrPartySelection";
import AngusPaletteContext from "../../../components/provider/AngusPaletteProvider";
import { CdrDashboardType } from "../../../data/constants/cdr-dashboard-type";

const CdrDashboardDetails : React.FC = () => {
    const [isSessionStatsLoading, setSessionStatsLoading] = useState<boolean>(false);
    const [isSessionStatOverTimeLoading, setSessionStatOverTimeLoading] = useState<boolean>(false);
    const [sessionStats, setSessionStats] = useState<CdrStatistics[]>();
    const [sessionStatsOverTime, setSessionStatsOverTime] = useState<CdrStatisticsOverTime[]>();
    const [dateRangePicker] = useRecoilState(dateRangePickerState)
    const [cdrDashboardType] = useRecoilState(cdrDashboardTypeState)
    const [cdrIssuerSelect] = useRecoilState(cdrPartySelectState)
    const provider = useDataProvider<typeof dataProvider>();
    const notify = useNotify();
    const {updatePalette} = useContext(AngusPaletteContext)

    useEffect(() => {
        setSessionStatsLoading(true);
        let resProm: Promise<CdrStatistics[]>;
        switch(cdrDashboardType) {
            case CdrDashboardType.ISSUERS:
                resProm = provider.getIssuerCdrStats(dateRangePicker, cdrIssuerSelect);
                break;
            case CdrDashboardType.PARTY:
                resProm = provider.getPartyCdrStats(dateRangePicker);
                break;
        }

        resProm.then(
            (resp) => {
                setSessionStats(resp);
                updatePalette(resp.map(v => v.name))
                setSessionStatsLoading(false);
            }
        ).catch((err: any) => notify(err.toString(), {type: 'error'}))

    }, [provider, notify, dateRangePicker, cdrDashboardType, cdrIssuerSelect, updatePalette]);

    useEffect(() => {
        setSessionStatOverTimeLoading(true);
        provider.getCdrOverTimeStats(cdrDashboardType, cdrIssuerSelect)
        .then(
            (resp) => {
                setSessionStatsOverTime(resp);
                updatePalette(resp.map(v => v._id))
                setSessionStatOverTimeLoading(false);
            })
        .catch((err: any) => notify(err.toString(), {type: 'error'}))
    }, [provider, notify, cdrDashboardType, cdrIssuerSelect, updatePalette])

    return (
        <Box sx={{display: "flex", flexDirection: "row", justifyContent: "center", mx: 5}}>
            <Box>
                <RemoteContentDisplay isLoading={isSessionStatsLoading} dataArray={sessionStats}>
                    <CdrPieChart sessionStats={sessionStats}></CdrPieChart>
                </RemoteContentDisplay>
                <RemoteContentDisplay isLoading={isSessionStatOverTimeLoading} dataArray={sessionStatsOverTime}>
                    <CdrLineChart sessionStatsOverTime={sessionStatsOverTime}></CdrLineChart>
                </RemoteContentDisplay>
            </Box>
        </Box>
    );
}


export default CdrDashboardDetails;
