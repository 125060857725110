import Button from "@mui/material/Button";
import {useState} from "react";
import {
    LinearProgress,
    SaveButton,
    SimpleForm, TextInput,
    Toolbar,
    useDataProvider,
    useNotify,
    useTranslate
} from "react-admin";
import {Dialog, DialogTitle, DialogContent, DialogContentText} from '@mui/material';
import * as React from "react";

export default function ButtonSendTestStatsEmail() {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const notify = useNotify();
    const provider = useDataProvider();
    const translate = useTranslate();

    const handleClose = () => setOpen(false);

    const handleSubmit = (data: any) => {
        setLoading(true);
        provider.sendTestStatsEmail({
                recipients: data.recipients.split(',').map((r: string) => r.trim())
            })
            .then((json: any) => {
                notify('exploitation.admin.sendMailStatTest.success');
                setOpen(false);
            })
            .catch((e: any) => {
                if (e.message) {
                    notify(`Erreur : ${e.message}`, {type: "error"});
                }
                else {
                    notify('exploitation.admin.sendMailStatTest.errorGeneric', {type: "error"});
                }
            })
            .finally(() => setLoading(false));
    }

    return (<>
        <Button variant={'contained'} onClick={() => setOpen(true)}>{translate('exploitation.admin.sendMailStatTest.cta')}</Button>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{translate('exploitation.admin.sendMailStatTest.dialog.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {translate('exploitation.admin.sendMailStatTest.dialog.text')}
                </DialogContentText>
                <SimpleForm onSubmit={handleSubmit} toolbar={<FormToolbar loading={loading}/>}>
                    <TextInput source="recipients" label={'exploitation.admin.sendMailStatTest.dialog.recipients'} required/>
                </SimpleForm>
            </DialogContent>
        </Dialog>
    </>);
}

const FormToolbar = ({loading}: {loading: boolean}) => {
    return <Toolbar sx={{justifyContent: "center"}}>
        {loading ? <LinearProgress/> : <SaveButton data-testid="saveOverrideButton" label={'exploitation.admin.sendMailStatTest.dialog.send'} />}
    </Toolbar>
}
