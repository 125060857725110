import {
    Button,
    useNotify,
    useDataProvider,
    DateTimeInput,
    SimpleForm,
    Toolbar,
    SaveButton,
    LinearProgress,
    useRefresh,
    NumberInput,
    useRecordContext, required, useTranslate
} from "react-admin";
import * as React from "react";
import DangerIcon from "@mui/icons-material/Warning";
import {useState} from "react";
import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, } from '@mui/material';
import {Cdr} from "../../../data/cdr";

export const ButtonOverrideCost = () => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();
    const provider = useDataProvider();
    const current = useRecordContext<Cdr>();

    const translate = useTranslate();

    const handleClose = () => setOpen(false);

    const handleSubmit = (data: any) => {
        setLoading(true);
        provider.patchCostOverride({
            cdrId: current!.cdr.id,
            ...data})
            .then((json: any) => {
                notify('resources.cdrs.overrides.success');
                refresh();
                setOpen(false);
            })
            .catch((e: any) => {
                if (e.message) {
                    notify(`Erreur : ${e.message}`, {type: "error"});
                }
                else {
                    notify('Erreur de modification du coût', {type: "error"});
                }
            })
            .finally(() => setLoading(false));
    }

    return <>
        <Button
            onClick={() => setOpen(true)}
            label={translate('resources.cdrs.overrides.cta')}
            variant={'contained'} color="warning" startIcon={<DangerIcon/>}
            data-testid="overrideCostButton" />
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{translate('resources.cdrs.overrides.dialog.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {translate('resources.cdrs.overrides.dialog.text')}
                </DialogContentText>
                <SimpleForm onSubmit={handleSubmit} toolbar={<FormToolbar loading={loading}/>} data-testid="overrideCostForm"
                    record = {{
                        start_date_time: current?.cdr.start_date_time,
                        stop_date_time: current?.cdr.stop_date_time,
                        total_cost: current?.cdr.total_cost
                    }}
                >
                    <DateTimeInput source="start_date_time" name={'start_date_time'} data-testid="dateFromInput"/>
                    <DateTimeInput source="stop_date_time" name={'stop_date_time'} data-testid="dateToInput"/>
                    <NumberInput source="total_cost" name={'total_cost'} validate={required()} data-testid="totalCostInput"/>
                </SimpleForm>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} label={translate('cta.cancel')} />
            </DialogActions>
        </Dialog>
    </>
}

const FormToolbar = ({loading}: {loading: boolean}) => {
    return <Toolbar sx={{justifyContent: "center"}}>
        {loading ? <LinearProgress/> : <SaveButton data-testid="saveOverrideButton"/>}
    </Toolbar>
}
