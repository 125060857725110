import {
    Create, SimpleForm, ValidateForm,
} from 'react-admin';
import React from "react";
import {Tarifffields} from "./TariffFields";
import Box from "@mui/material/Box";

export const validateTariff : ValidateForm = (values) => {
    const errors = {};

    // Check unicity of tariff key
    let tariffkeys : string[] = [];

    for (let row of (values.subtariffs ?? [])) {
        if (tariffkeys.includes(row.tariffkey)) {
            // @ts-ignore
            errors.subtariffs = "You can't use the same tariffkey multiple times";
            break;
        }

        tariffkeys.push(row.tariffkey);
    }

    return errors;
}

export const TariffgroupCreate = () => (
    <Box>
        <Create>
            <SimpleForm>
                <Tarifffields/>
            </SimpleForm>
        </Create>
    </Box>
);
