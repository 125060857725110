import { ReactNode } from 'react';
import {LinearProgress} from "react-admin";
import Box from "@mui/material/Box";

interface RemoteContentDisplayProps {
    isLoading: boolean;
    dataArray: any[] | undefined;
    children: ReactNode;
}

// TODO : replace DashboardSection ???
export const RemoteContentDisplay: React.FC<RemoteContentDisplayProps> = ({isLoading, dataArray, children}) => {
    if (isLoading) {
        return (<Box sx={{display: "flex", justifyContent: "center"}}>
                <LinearProgress></LinearProgress>
        </Box>);
    } else {
      return <>{children}</>;
    }
}
