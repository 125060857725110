import {InputProps, SearchInput} from "react-admin";
import HintIcon from "@mui/icons-material/LightbulbOutlined";
import Tooltip from '@mui/material/Tooltip';
import Box from "@mui/material/Box";

interface SearchHintInputProps extends InputProps {
    hint: string
}

export default function SearchHintInput({hint, ...rest} : SearchHintInputProps) {
    return (<Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
        <SearchInput {...rest} />
        <Tooltip title={hint} sx={{marginTop: "8px", color: 'text.disabled'}}><HintIcon/></Tooltip>
    </Box>);
}
