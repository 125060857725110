import {useRecordContext} from "react-admin";
import {Chargebox} from "../../../../data/exploitation/chargebox";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import RebootIcon from "@mui/icons-material/Refresh";
import {toFrenchDate} from "../../../../../helpers/helper";


export default function OperationsColumn({source, label}: {source: string, label: string}) {
    const record = useRecordContext<Chargebox>();

    if (!record || !record.operations?.length) {
        return null;
    }

    const pendingOperations = record.operations.filter((op) => !op.completed);

    return <Box>
        {pendingOperations.map((op, k) => {
            return <Card variant={'outlined'} key={k} sx={{marginY: 1}}>
                <CardHeader title={op.status} avatar={<RebootIcon/>} subheader={toFrenchDate(op.started)}/>
            </Card>;
        })}
    </Box>;
}
