import {
    AutocompleteInput,
    DatagridConfigurable,
    DateField, Empty, FilterButton,
    List,
    NumberField,
    ReferenceArrayField, ReferenceInput, SelectColumnsButton, SelectInput,
    TextField, TopToolbar,
} from "react-admin";
import React from "react";
import {AppPermissions} from "../../data/constants/permissions";
import {ButtonTriggerSync} from "../cdrs/widgets/ButtonTriggerSync";
import Box from "@mui/material/Box";
import {useIsAllowed} from "../../../helpers/useIsAllowed";

const ListActions = () => {
    const { isAllowed } = useIsAllowed();

    return (
        <TopToolbar>
            <SelectColumnsButton/>
            <FilterButton/>
            {isAllowed(AppPermissions.CdrsSync) && <ButtonTriggerSync/>}
        </TopToolbar>
    );
};

const EmptyList = () => {
    const { isAllowed } = useIsAllowed();
    return (
        <Box textAlign="center" m={1} sx={{marginLeft: "auto", marginRight: "auto"}}>
            <Empty/>
            {isAllowed(AppPermissions.CdrsSync) && <ButtonTriggerSync/>}
        </Box>
    );
};

const listFilters = [
    <ReferenceInput name="emsp" source="emsp" reference={"exploitationEmsps"} key="emsps" label={"Emsp"}>
        <AutocompleteInput optionText="party_id" optionValue="party_id" label={"Emsp"}/>
    </ReferenceInput>,
    <SelectInput name="status" source="status" choices={[
        { id: 'pending', name: 'En attente' },
        { id: 'completed', name: 'Terminé' },
        { id: 'failed', name: 'Échoué' },
    ]}/>,
    <SelectInput name="type" source="type" choices={[
        { id: 'manual', name: 'Manuel' },
        { id: 'scheduled', name: 'Programmé' },
    ]}/>
];

export const CdrImportJobList = () => {
    return (<List
        actions={<ListActions/>}
        empty={<EmptyList/>}
        filters={listFilters}
        sort={{field: "created", order: "DESC"}}
    >
        <DatagridConfigurable rowClick="show" bulkActionButtons={false}
                              omit={[
                                  'id'
                              ]}>
            <TextField source="id"/>
            <DateField source="created" showTime={true} locales={'fr-FR'}/>
            <TextField source="type" />
            <DateField source="dateFrom" showTime={true} locales={'fr-FR'}/>
            <DateField source="dateTo" showTime={true} locales={'fr-FR'}/>
            <ReferenceArrayField source="emsps" reference="exploitationEmsps"/>
            <TextField source="status" />
            <DateField source="executed" showTime={true} locales={'fr-FR'}/>
            <NumberField source="imported_count" />
            <TextField source="error" />
        </DatagridConfigurable>
    </List>);
}
