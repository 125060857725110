import Button from "@mui/material/Button";
import {useState} from "react";
import {
    DateInput,
    LinearProgress, SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    useDataProvider,
    useNotify,
    useTranslate,
} from "react-admin";
import {Dialog, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import * as React from "react";

export default function ButtonResendStatsEmail() {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showDateRange, setShowDateRange] = useState(false);
    const notify = useNotify();
    const provider = useDataProvider();
    const translate = useTranslate();

    const handleClose = () => setOpen(false);

    const handleSubmit = (data: any) => {
        setLoading(true);
        let payload : any = {};

        if (data.subject) {
            payload.subject = data.subject;
        }

        if (data.range.from && data.range.to) {
            payload.range = {
                from: data.range.from,
                to: data.range.to
            }
        }

        provider.resendStatsEmail(payload)
            .then((json: any) => {
                notify('exploitation.admin.resendStatsMailing.success');
                setOpen(false);
            })
            .catch((e: any) => {
                if (e.message) {
                    notify(`Erreur : ${e.message}`, {type: "error"});
                }
                else {
                    notify('exploitation.admin.resendStatsMailing.errorGeneric', {type: "error"});
                }
            })
            .finally(() => setLoading(false));
    }

    return (<>
        <Button variant={'contained'} onClick={() => setOpen(true)}>{translate('exploitation.admin.resendStatsMailing.cta')}</Button>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{translate('exploitation.admin.resendStatsMailing.dialog.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {translate('exploitation.admin.resendStatsMailing.dialog.text')}
                </DialogContentText>
                <SimpleForm onSubmit={handleSubmit} toolbar={<FormToolbar loading={loading}/>}>
                    <TextInput source="subject" label={'exploitation.admin.resendStatsMailing.dialog.subject'} />
                    {showDateRange ? <>
                        <DateInput source="range.from" label={'exploitation.admin.resendStatsMailing.dialog.rangeFrom'} />
                        <DateInput source="range.to" label={'exploitation.admin.resendStatsMailing.dialog.rangeTo'} />
                    </> : <Button onClick={() => setShowDateRange(true)} variant={'outlined'}>{translate('exploitation.admin.resendStatsMailing.dialog.showRange')}</Button>}
                </SimpleForm>
            </DialogContent>
        </Dialog>
    </>);
}

const FormToolbar = ({loading}: {loading: boolean}) => {
    return <Toolbar sx={{justifyContent: "center"}}>
        {loading ? <LinearProgress/> : <SaveButton data-testid="saveOverrideButton" label={'exploitation.admin.resendStatsMailing.dialog.send'} alwaysEnable/>}
    </Toolbar>
}
