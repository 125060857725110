import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import ExploitationDailyStatsMailingConfigPage from "./ExploitationDailyStatsMailingConfigPage";
import ButtonSendTestStatsEmail from "./widgets/ButtonSendTestStatsEmail";
import ButtonResendStatsEmail from "./widgets/ButtonResendStatsEmail";

export default function ExploitationAdminPage() {
    return (
        <Box>
            <div>
                <h1>Administration de l'exploitation</h1>
            </div>
            <Card>
                <CardContent>
                    <h2>Email de stats quotidien</h2>
                    <Box sx={{display: 'flex', gap: 2}}>
                        <Button variant={'contained'} component={Link}
                                to={ExploitationDailyStatsMailingConfigPage.route}>Configuration</Button>
                        <ButtonResendStatsEmail/>
                        <ButtonSendTestStatsEmail/>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
}

ExploitationAdminPage.route = '/exploitation/admin';
