import {Button, useDataProvider, useNotify, useRefresh} from "react-admin";
import {useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {E55cApiDataProvider} from "../../../../../providers/dataProvider";

export default function GenerateInvoicesButton() {
    const [sending, isSending] = useState(false);
    const dataProvider = useDataProvider<E55cApiDataProvider>();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleClick = () => {
        isSending(true);

        dataProvider.generateInvoices()
            .then(() => {
                notify('Création des factures terminée');
            })
            .catch(err => {
                notify('Erreur lors de la création des factures', {type: "error"});
                refresh();
            })
            .finally(() => isSending(false));
    }

    if (sending) {
        return <CircularProgress size={20}/>;
    }

    return (<Button color={'primary'} variant={'outlined'} label={'Générer les factures'} onClick={handleClick}></Button>);
}
