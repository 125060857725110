import {Cdr} from "../../data/cdr";
import {downloadCSV} from "react-admin";
import jsonExport from 'jsonexport/dist';

export default function exporter(records: Cdr[]) {
    const rowsForExport = records.map((record) => {
        return {
            id: record.id,
            party_id: record.party_id,
            token: {
                issuer: record.issuer,
                auth_id: record.cdr.auth_id,
                uid: record.uid,
            },
            start_datetime: record.cdr.start_date_time,
            stop_datetime: record.cdr.stop_date_time,
            total_cost: record.cdr.total_cost,
            total_energy: record.cdr.total_energy,
            total_parking_time: record.cdr.total_parking_time,
            total_time: record.cdr.total_time,
            location: {
                // name: record.location.name,
                id: record.cdr.location.id,
                evses: record.cdr.location.evses?.map(evse => ({
                    evse_id: evse.evse_id,
                    connectors: evse.connectors.map(con => ({
                        id: con.id
                    }))
                }))
            }
        };
    });
    jsonExport(
        rowsForExport,
        {
            fillGaps: true,
            rowDelimiter: ';',
            headers: [
                'id',
                'location.id',
                // 'location.country',
                'location.evses.evse_id',
                'location.evses.connectors.id',
                'party_id',
                'token.issuer',
                'token.auth_id',
                // 'authorization_id',
                'token.uid',
                'start_datetime',
                'stop_datetime',
                'total_cost',
                'total_energy',
                'total_parking_time',
                'total_time',
            ],
            // mapHeaders: (header: string) : string => {
            //     return header;
            // },
        },
        (err: any, csv: any) => {
            downloadCSV(csv, 'cdrs'); // download as 'posts.csv` file
        }
    );
};
