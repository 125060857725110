import { useContext, useEffect, useState} from "react";
import {CdrStatistics} from "../../../../providers/dto/CdrStats";
import {DashboardDateOption} from "../../../data/constants/dashboard-date-option";
import {useDataProvider, useNotify} from "react-admin";
import dataProvider from "../../../../providers/dataProvider";
import DashboardSection from "../../../features/dashboard/DashboardSection";
import {RemoteContentDisplay} from "../../../components/layout/RemoteContentDisplay";
import {CdrDashboardTable} from "../../../features/dashboard/session/CdrDashboardTable";
import Box from "@mui/material/Box";
import DateRangePicker, { dateRangePickerState } from "../../../features/dashboard/DateRangePicker";
import { useRecoilState } from "recoil";
import CdrDashboardTypeToggleGroup, { cdrDashboardTypeState } from "../../../features/dashboard/session/CdrDashboardToggleGroup";
import { cdrPartySelectState } from "../../../features/dashboard/session/CdrPartySelection";
import AngusPaletteContext from "../../../components/provider/AngusPaletteProvider";
import { CdrDashboardType } from "../../../data/constants/cdr-dashboard-type";

export default function CdrsDetailsStatsTable() {
    const [isSessionStatsLoading, setSessionStatsLoading] = useState<boolean>(false);
    const [sessionStats, setSessionStats] = useState<CdrStatistics[]>();
    const provider = useDataProvider<typeof dataProvider>();
    const notify = useNotify();
    const [dateRangePicker] = useRecoilState(dateRangePickerState);
    const [cdrDashboardType] = useRecoilState(cdrDashboardTypeState);
    const [cdrIssuerSelect] = useRecoilState(cdrPartySelectState);

    const {updatePalette} = useContext(AngusPaletteContext)

    useEffect(() => {
        setSessionStatsLoading(true);
        let resProm: Promise<CdrStatistics[]>;
        switch(cdrDashboardType) {
            case CdrDashboardType.ISSUERS:
                resProm = provider.getIssuerCdrStats(dateRangePicker, cdrIssuerSelect);
                break;
            case CdrDashboardType.PARTY:
                resProm = provider.getPartyCdrStats(dateRangePicker);
                break;
        }

        resProm.then(
            (resp) => {
                setSessionStats(resp);
                updatePalette(resp.map(v => v.name))
                setSessionStatsLoading(false);
            }
        ).catch((err: any) => notify(err.toString(), {type: 'error'}))
    }, [provider, notify, dateRangePicker, cdrDashboardType, cdrIssuerSelect, updatePalette]);


    return (
        <DashboardSection title="Tableau détaillé">
            <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                <Box sx={{display: "flex", justifyContent: "center"}}>
                    <CdrDashboardTypeToggleGroup></CdrDashboardTypeToggleGroup>
                </Box>
                <Box sx={{display: "flex", justifyContent: "center", margin: 1}}>
                    <DateRangePicker
                        value={dateRangePicker}
                        dateOptBag={[DashboardDateOption.TODAY, DashboardDateOption.ONE_DAY, DashboardDateOption.SEVEN_DAYS, DashboardDateOption.THIRTY_DAYS, DashboardDateOption.ONE_HUNDRED_EIGHTY_DAYS, DashboardDateOption.YEAR_TO_DATE, DashboardDateOption.ONE_YEAR]}
                    ></DateRangePicker>
                </Box>
            </Box>
            <RemoteContentDisplay isLoading={isSessionStatsLoading} dataArray={sessionStats}>
                <CdrDashboardTable sessionStats={sessionStats}></CdrDashboardTable>
            </RemoteContentDisplay>
        </DashboardSection>
    )
}
