import {SelectArrayInput, SelectArrayInputProps, useDataProvider} from "react-admin";
import {useEffect, useState} from "react";
import {E55cApiDataProvider} from "../../../../../providers/dataProvider";

export default function SelectTagsInput(props: SelectArrayInputProps) {
    const [tags, setTags] = useState<string[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const provider = useDataProvider<E55cApiDataProvider>();

    useEffect(() => {
        provider.getLocationsTags()
            .then(data => {
                setTags(data);
                setLoading(false);
            })
            .catch(() => {
                setError(true);
                setLoading(false);
            });
    }, [provider]);

    if (error) {
        return <p>Erreur de récupération des tags</p>;
    }

    if (loading) {
        return null;
    }

    return <SelectArrayInput
        onCreate={() => {
            const newTagName = prompt('Nouveau tag');

            if (!newTagName) {
                return;
            }

            setTags([...tags, newTagName.toLowerCase()]);
        }}
        {...props}
        choices={tags.map(tag => ({
            id: tag,
            name: tag,
        })) ?? []}
        format={v => {
            if (!v) {
                return [];
            }

            if (v?.includes('@@ra-create')) {
                return v?.filter((tag: string) => tag !== '@@ra-create') ?? [];
            }

            return v;
        }}
    />;
}
