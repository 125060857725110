import CdrDashboardDetails from "./CdrDashboardDetails";
import Box from "@mui/material/Box";
import DashboardSection from "../DashboardSection";
import CdrDashboardTypeToggleGroup from "./CdrDashboardToggleGroup";
import DateRangePicker, { dateRangePickerState } from "../DateRangePicker";
import { useRecoilState } from "recoil";
import { DashboardDateOption } from "../../../data/constants/dashboard-date-option";

const CdrSection = () => {
    const [dateRangePicker] = useRecoilState<DashboardDateOption>(dateRangePickerState)

    return(
        <DashboardSection title={'Charges'}>
            <Box maxWidth="85vw" width="100%" sx={{display: "flex", flexDirection: {xs: "column"}, gap: 3, alignItems: "center", overflowX: "hidden"}}>
                <CdrDashboardTypeToggleGroup></CdrDashboardTypeToggleGroup>
                <DateRangePicker
                    value={dateRangePicker}
                    dateOptBag={[DashboardDateOption.TODAY, DashboardDateOption.ONE_DAY, DashboardDateOption.SEVEN_DAYS, DashboardDateOption.THIRTY_DAYS, DashboardDateOption.ONE_HUNDRED_EIGHTY_DAYS, DashboardDateOption.YEAR_TO_DATE, DashboardDateOption.ONE_YEAR]}
                ></DateRangePicker>
                <CdrDashboardDetails />
            </Box>
        </DashboardSection>
    );
}


export default CdrSection;
