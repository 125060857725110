import {Cdr} from "../../../data/cdr";
import {FunctionField, useRecordContext} from "react-admin";
import {toEuro} from "../../../../helpers/helper";
import React from "react";
import Box from "@mui/material/Box";
import WarningIcon from "@mui/icons-material/Warning";
import Chip from "@mui/material/Chip";

export default function TotalCostField({source}: {source: string}) {
    const record = useRecordContext<Cdr>();

    if (!record) {
        return null;
    }

    const isModified = record.overrides?.find(override => Object.keys(override.changes).includes('total_cost')) !== undefined;
    const value = toEuro(record.cdr.total_cost);

    return (
        <FunctionField source="cdr.total_cost" render={(record: Cdr) =>
            <WarningWrapper showWarning={record.cdr.total_cost < 0}>
                {isModified ? <Chip label={value} sx={{backgroundColor: "orange", color: "white"}}/> : value}
            </WarningWrapper>}
        />
    );
}

const WarningWrapper = ({showWarning, children} : {showWarning: boolean, children: React.ReactNode}) => {
    return (showWarning ?
        <Box sx={{display: "flex", alignItems: "center", gap: 2}}><Box>{children}</Box><Box><WarningIcon height={12} /></Box></Box> : <Box>{children}</Box>);
}
