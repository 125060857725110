import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow'; 
import { toUnit } from '../../../../helpers/helper';
import { CdrStatistics } from '../../../../providers/dto/CdrStats';
import Paper from '@mui/material/Paper';
import { useRecoilState } from 'recoil';
import { cdrDashboardTypeState } from './CdrDashboardToggleGroup';
import { getLegendColorElement } from '../common/color-react-helper';
import { useContext } from 'react';
import AngusPaletteContext from '../../../components/provider/AngusPaletteProvider';
import { useTranslate } from 'react-admin';

interface CdrDashboardProps {
    sessionStats: CdrStatistics[] | undefined
}

export const CdrDashboardTable: React.FC<CdrDashboardProps> = ({sessionStats}) => {
    const [cdrDashboardType] = useRecoilState(cdrDashboardTypeState)
    const {palette} = useContext(AngusPaletteContext)
    const translate = useTranslate();

    return (
        <TableContainer sx={{ minWidth : 320, width: '85vw'}} component={Paper}>
            <Table size="medium" aria-label="Session cost table">
                <TableHead>
                    <TableRow>
                        <TableCell>{cdrDashboardType}</TableCell>
                        <TableCell >{translate("exploitation.stats.cdrs.table.totalCostLabel")}</TableCell>
                        <TableCell >{translate("exploitation.stats.cdrs.table.totalCostPercentLabel")}</TableCell>
                        <TableCell >{translate("exploitation.stats.cdrs.table.totalSessionLabel")}</TableCell>
                        <TableCell >{translate("exploitation.stats.cdrs.table.totalSessionPercentLabel")}</TableCell>
                        <TableCell >{translate("exploitation.stats.cdrs.table.meanTotalCostLabel")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sessionStats?.map((stat, index) => (
                        <TableRow
                            key={stat.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell>
                                { getLegendColorElement(palette.getColorForName(stat.name)) } {stat.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                { toUnit(stat.cost_data, '€') }
                            </TableCell>
                            <TableCell>
                                { percentageOf(stat, "cost_data", sessionStats) }
                            </TableCell>
                            <TableCell component="th" scope="row">
                                { stat.totalSession }
                            </TableCell>
                            <TableCell>
                                { percentageOf(stat, "totalSession", sessionStats) }
                            </TableCell>
                            <TableCell>
                                { toUnit((stat.cost_data / stat.totalSession) ?? 0, '€') }
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function percentageOf(dataLine: CdrStatistics, dataKey: keyof CdrStatistics, dataSet: CdrStatistics[]) : string {
    const numRes = (dataLine[dataKey] as number * 100) / getTotal(dataSet, dataKey);
    return isNaN(numRes) ? toUnit(0, "%") : toUnit(numRes, "%")
}

function getTotal(stats: CdrStatistics[] | undefined, dataKey: keyof CdrStatistics): number {
    return stats?.reduce((acc, val) => acc += val[dataKey] as number, 0) ?? 0;
} 