import {
    Edit, SimpleForm,
    TextInput
} from 'react-admin';
import {Tarifffields} from "./TariffFields";
import React from "react";

export const TariffgroupEdit = () => (
    <Edit>
        <SimpleForm>
            <Tarifffields/>
            <TextInput source="id" disabled/>
        </SimpleForm>
    </Edit>
);
