import {useEffect, useState} from "react";
import CardWithIcon from "./CardWithIcon";
import {IssuerIcon} from "../../constants";
import * as React from "react";
import {useDataProvider, useNotify} from "react-admin";
import dataProvider from "../../../providers/dataProvider";
import {MonthIssuersStats} from "../../../providers/dto/MonthIssuersStats";
import DashboardSection from "./DashboardSection";
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import {toEuro, tokWh, toUnit,} from "../../../helpers/helper";
import {Cell, Legend, Pie, PieChart, ResponsiveContainer} from "recharts";
import { generateColors } from "../../data/constants/AngusPalette";

const StatLine = ({label, value}: { label: string, value: string }) => {
    return <Box sx={{ display: "flex", justifyContent: "space-between"}}>
        <Typography>{label}</Typography><Box>{value}</Box>
    </Box>
}

const StatDisplay = ({stats}: {stats: MonthIssuersStats | undefined}) => {
    if (!stats) {
        return null;
    }

    const colors = generateColors(stats.issuers.length);

    return <Box sx={{p: 1}}>
        <Typography variant={'h6'}>Statistiques du mois dernier</Typography>
        <Box>
            <StatLine label={'Montant'} value={toEuro(stats.total.totalCost)} />
            <StatLine label={'Énergie'} value={tokWh(stats.total.totalEnergy)} />
            <StatLine label={'Temps de charge'} value={toUnit(stats.total.totalTime, ' heures')} />
            <StatLine label={'Nombre de charges'} value={toUnit(stats.total.totalCdrs, '')} />
            <StatLine label={'Temps de charge moyen'} value={toUnit(stats.total.totalTime/stats.total.totalCdrs, ' heures')} />
        </Box>
        <Box padding={1}>
            <ResponsiveContainer width="100%" height={300}>
                <PieChart width={400} height={400}>
                    <Pie dataKey={'totalCost'} data={stats.issuers} nameKey={'issuer'} cx={'50%'} cy={'50%'} fill="#8884d8" outerRadius={80} label={(entry) => {
                        return toUnit(entry.value, ' €');
                    }}>
                        {stats.issuers.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                        ))}
                    </Pie>
                    <Legend layout="horizontal" verticalAlign="top" align="center" />
                </PieChart>
            </ResponsiveContainer>
        </Box>
    </Box>
}

export default function StatsInvoicing() {
    const [stats, setStats] = useState<MonthIssuersStats>();
    const [loading, isLoading] = useState(true);
    const provider = useDataProvider<typeof dataProvider>();
    const notify = useNotify();
    const sxCard = {
        minWidth: {
            md: 500,
        }
    };

    useEffect(() => {
        provider.getPreviousMonthIssuersStats()
            .then((resp) => setStats(resp))
            .catch((err: any) => notify(err.toString(), {type: 'error'}))
            .finally(() => isLoading(false))
    }, [provider, notify]);

    return (<DashboardSection title={'Pre-facturation'}>
        <CardWithIcon icon={IssuerIcon} to={'invoicingIssuers'} title={'Issuers'} subtitle={loading ? '-' : stats?.total.totalIssuers} sx={sxCard}>
            {!loading && <StatDisplay stats={stats}></StatDisplay>}
        </CardWithIcon>
    </DashboardSection>);
}
