import { defaultTheme } from 'react-admin';
import {createTheme} from "@mui/material/styles";

export const Yess55Theme= {
    light: {
        ...defaultTheme,
        ...createTheme({palette: {
                primary: {
                    main: '#248638'
                }
            }
        }),
    },
    dark: {
        ...defaultTheme,
        ...createTheme({palette: {
                mode: 'dark',
                primary: {
                    main: '#248638'
                }
            }
        }),
    }
};
