import {AutocompleteInputProps, useDataProvider, AutocompleteInput} from "react-admin";
import {useEffect, useState} from "react";
import {E55cApiDataProvider} from "../../../../../providers/dataProvider";

export default function SelectTagInput(props: AutocompleteInputProps) {
    const [tags, setTags] = useState<string[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const provider = useDataProvider<E55cApiDataProvider>();

    useEffect(() => {
        provider.getChargeboxesTags()
            .then(data => {
                setTags([
                    ...data,
                ]);
                setLoading(false);
            })
            .catch(() => {
                setError(true);
                setLoading(false);
            });
    }, [provider]);

    if (error) {
        return <p>Erreur de récupération des tags</p>;
    }

    if (loading) {
        return null;
    }

    return <AutocompleteInput
        onCreate={(newTagName) => {
            setTags([...tags, newTagName!.toLowerCase()]);

            return {
                id: newTagName,
                name: newTagName,
            };
        }}
        {...props}
        choices={tags.map(tag => ({
            id: tag,
            name: tag,
        })) ?? []}
    />;
}
