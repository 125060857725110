import z from 'zod';

const truthyStringSchema = z.enum(["true", "false", "0", "1"]).transform((v) => v === "true" || v === "1");

const appConfigSchema = z.object({
    TENANT: z.string().trim().default(''),
    COGNITO_USERPOOL_ID: z.string().default(''),
    COGNITO_APP_CLIENT_ID: z.string().default(''),
    COGNITO_APP_HOSTED_UI_URL: z.string().default(''),
    BASE_API_URL: z.string(),
    APP_NAME: z.string().default(''),
    TARIFFS_ENABLED: truthyStringSchema.default("false"),
    TARIFF_KEYS: z.string().optional(),
    XLSX_ENABLED: truthyStringSchema.default("false"),
    FEATURE_SHOW_STATS: truthyStringSchema.default("false"),
    FEATURE_SHOW_INVOICING: truthyStringSchema.default("false"),
    FEATURE_OVERWRITE_CDRS_COST: truthyStringSchema.default("false"),
    FEATURE_EXPLOITATION_STATS_MAILING: truthyStringSchema.default("false"),

    APP_VERSION: z.string().default('x.y.z'),
});

const configParsed = appConfigSchema.safeParse({
    TENANT: process.env.REACT_APP_TENANT,
    COGNITO_USERPOOL_ID: process.env.REACT_APP_COGNITO_USERPOOL_ID,
    COGNITO_APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    COGNITO_APP_HOSTED_UI_URL: process.env.REACT_APP_COGNITO_APP_HOSTED_UI_URL,
    BASE_API_URL: process.env.REACT_APP_BASE_API_URL || process.env.REACT_APP_AWS_API_URL,
    APP_NAME: process.env.REACT_APP_APP_NAME,
    TARIFFS_ENABLED: process.env.REACT_APP_TARIFFS_ENABLED || "false",
    TARIFF_KEYS: process.env.REACT_APP_TARIFF_KEYS,
    XLSX_ENABLED: process.env.REACT_APP_XLSX_ENABLED || "false",
    APP_VERSION: process.env.REACT_APP_APP_VERSION,

    FEATURE_SHOW_STATS: process.env.REACT_APP_FEATURE_SHOW_STATS || "false",
    FEATURE_SHOW_INVOICING: process.env.REACT_APP_FEATURE_SHOW_INVOICING || "false",
    FEATURE_OVERWRITE_CDRS_COST: process.env.REACT_APP_FEATURE_OVERWRITE_CDRS_COST || "false",
    FEATURE_EXPLOITATION_STATS_MAILING: process.env.REACT_APP_FEATURE_EXPLOITATION_STATS_MAILING || "false",
});

if (!configParsed.success) {
    console.error(configParsed.error.issues);
    throw new Error('There is an error with the environment variables');
}

export default configParsed.data;
