import { StepIconProps } from '@mui/material/StepIcon';
import { styled } from '@mui/material/styles';

interface E55CCustomStepIconProps extends StepIconProps {
    value: string | number
}

export const E55CCustomStepIcon: React.FC<E55CCustomStepIconProps> = (props: E55CCustomStepIconProps) => {
    const { active, completed, className, value } = props;
  
    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {value}
      </ColorlibStepIconRoot>
    );
  }

  const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 85,
    height: 85,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize : "30px",
    ...(ownerState.active && {
      backgroundImage:
        'linear-gradient( 136deg, ' + theme.palette.primary.dark + ' 0%, ' + theme.palette.primary.light+' 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundImage:
        'linear-gradient( 136deg, ' + theme.palette.primary.dark + ' 0%, ' + theme.palette.primary.light+' 100%)',
    }),
  }));