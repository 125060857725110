import { DashboardDateOption, translateDateOption } from '../../data/constants/dashboard-date-option';
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import { atom, useRecoilState } from 'recoil';

interface DateRangePickerProps {
    value: DashboardDateOption,
    dateOptBag: DashboardDateOption[]
}
export const dateRangePickerState = atom<DashboardDateOption>({
    key: "date-range",
    default: DashboardDateOption.TODAY
})

const DateRangePicker: React.FC<DateRangePickerProps> = ({dateOptBag}) => {

    const[datePickerState, setDatePickerState] = useRecoilState(dateRangePickerState)

    const updateDatePickerState = (event: React.MouseEvent<HTMLElement>, value: DashboardDateOption) => {
        setDatePickerState(value)
    }

    return(
        <ToggleButtonGroup value={datePickerState}>
            {
                dateOptBag.map((el) => (
                    <ToggleButton key={translateDateOption(el)} value={el} onClick={updateDatePickerState} sx={{minWidth: {sm : '4em'}}}>{translateDateOption(el)}</ToggleButton>
                ))
            }
        </ToggleButtonGroup>
    );
}

export default DateRangePicker;
