import {Datagrid, DateField, FunctionField, List, TextField} from "react-admin";
import React from "react";
import {TaskRequestMessage} from "../../../data/exploitation/taskrequestmessage";
import ChargeboxesSubMenuBar from "../components/ChargeboxesSubMenuBar";

export default function TaskRequestList() {
    return <>
        <ChargeboxesSubMenuBar/>
        <List<TaskRequestMessage> sort={{field: "sentAt", order: "DESC"}}>
        <Datagrid rowClick="show" bulkActionButtons={false}>
            <DateField source="sentAt" sortable={true} showTime={true} locales={'fr-FR'}/>
            <FunctionField source="chargeboxes" sortable={true} render={(record) => record.chargeboxes[0]}/>
            <TextField source="taskType" sortable={true}/>
            <TextField source="key" sortable={true}/>
            <TextField source="value" sortable={true}/>
            <TextField source="connectorId" sortable={true}/>
        </Datagrid>
    </List>
    </>
}
