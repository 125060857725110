import {usePermissions} from "react-admin";
import {AppPermissions} from "../admin/data/constants/permissions";

function isAllowed(permission: string, userPermissions: string[]) : boolean  {
    if (!userPermissions) {
        return false;
    }

    return userPermissions.includes(AppPermissions.SuperAdmin) || userPermissions.includes(permission)
}


function isAllowedAny(permission: string[], userPermissions: string[]) : boolean {
    if (!userPermissions) {
        return false;
    }

    if (userPermissions.includes(AppPermissions.SuperAdmin)) {
        return true;
    }

    return permission.some(p => userPermissions.includes(p));
}

export function useIsAllowed() : {isAllowed: (permission: string) => boolean, isAllowedAny: (permissions: string[]) => boolean} {
    const {permissions: userPermissions} = usePermissions();

    return {
        isAllowed: (permission: string) : boolean => isAllowed(permission, userPermissions),
        isAllowedAny: (permissions: string[]) : boolean => isAllowedAny(permissions, userPermissions),
    };
}
