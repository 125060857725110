import { atom, useRecoilState } from "recoil";
import { CdrDashboardType } from "../../../data/constants/cdr-dashboard-type";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CdrPartySelection from "./CdrPartySelection";
import { useTranslate } from "react-admin";
import Box from "@mui/material/Box";

export const cdrDashboardTypeState = atom<CdrDashboardType>({
    key: "cdr-dashboard-type",
    default: CdrDashboardType.PARTY
})

const CdrDashboardTypeToggleGroup = () => {
    const [cdrDashboardType, setCdrDashboardType] = useRecoilState(cdrDashboardTypeState)
    const translate = useTranslate();

    return (
        <Box sx={{}}>
            <Box sx={{paddingY: 2}}>
                <ToggleButtonGroup value={cdrDashboardType} color={'primary'} exclusive onChange={(event, newValue) => setCdrDashboardType(newValue)}>
                    <ToggleButton value={CdrDashboardType.PARTY} aria-label="Party" sx={{display: "flex", gap: 1, alignItems: "center"}}>
                        {translate("exploitation.stats.cdrs.toggleEMSP")}
                    </ToggleButton>
                    <ToggleButton value={CdrDashboardType.ISSUERS} aria-label="Issuer" sx={{display: "flex", gap: 1, alignItems: "center"}}>
                    {translate("exploitation.stats.cdrs.toggleIssuer")}
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
            <Box sx={{paddingY: 2}}>
            {cdrDashboardType === CdrDashboardType.ISSUERS && (
                <CdrPartySelection/>
            )}
            </Box>
        </Box>
    );
}

export default CdrDashboardTypeToggleGroup;
