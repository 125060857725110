import iwanthue, { ColorSpace } from "iwanthue";
import Palette, { PaletteGenerateFromValuesSettings } from "iwanthue/palette"

// TODO : check values existance in palette and prevent refresh if not needed
export default class AngusPalette {
    palette : Palette<string>;

    domainSet: Set<string>;

    constructor(domain: string[] = [""], isDarkTheme: boolean = false, colorblind: boolean = false) {
        this.domainSet = new Set<string>(domain);
        this.palette = this.generatePalette(isDarkTheme, colorblind);
    }

    getColorForName(name: string): string {
        return this.palette.get(name);
    }

    refresh(domain: string[], isDarkTheme: boolean, colorblind: boolean = false) {
        domain.forEach(v => this.domainSet.add(v));
        this.palette = this.generatePalette(isDarkTheme, colorblind);
    }

    private generatePalette(isDarkTheme: boolean, colorblind: boolean = false): Palette<string> {
        return Palette.generateFromValues(
            "nameToColors",
            this.domainSet,
            this.getHueParameters(colorblind, isDarkTheme)
        )
    }

    private getColorForTheme(isDark: boolean): ColorSpace {
        return isDark ? "sensible" : "fancy-light";
    }
    
    private getHueParameters(colorblind: boolean, isDarkTheme : boolean): PaletteGenerateFromValuesSettings {
        return {
            defaultColor: "red",
            colorSpace: colorblind ? "colorblind" : this.getColorForTheme(isDarkTheme),
            quality: 100,
            clustering: "k-means",
            distance: colorblind ? "compromise" : "euclidean"
        }
    }
}

export function generateColors(numColors: number): string[] {
    return iwanthue(numColors <= 0 ? 1 : numColors);
}
