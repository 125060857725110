import {
    SimpleShowLayout, Show, TextField, ReferenceField
} from 'react-admin';

export const TarifflinkShow = () => (
    <Show>
        <SimpleShowLayout>
            <ReferenceField source="tariffgroup_id" reference="tariffgroups" label={"Tariff group"}>
                <TextField source="tariffgroup_id" />
            </ReferenceField>
            <ReferenceField source="party_id" reference="tariffemsps" label={"EMSP"}>
                <TextField source="party_id" />
            </ReferenceField>
            <ReferenceField source="location_id" reference="tarifflocations"  label={"Location"}>
                <TextField source="location_id" />
            </ReferenceField>
            <TextField source="id" />
        </SimpleShowLayout>
    </Show>
);
