
export const toEuro = (nb: number) : string => new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
}).format(nb);

export const toUnit = (nb: number, unit: string) : string => new Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(nb) + ' ' + unit;

export const tokWh = (nb: number) : string => toUnit(nb, 'kWh');

export const toFrenchDate = (val: string) : string => {
    const date = new Date(val);

    return new Intl.DateTimeFormat('fr-FR',  {dateStyle: 'short', timeStyle: 'medium'}).format(date)
}
