import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';

export const ColorlibConnector = styled(StepConnector)(({ theme}) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,' + theme.palette.primary.dark + ' 0%,' + theme.palette.primary.light+' 100%)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,' + theme.palette.primary.dark + ' 0%,' + theme.palette.primary.light+' 100%)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      marginTop: "12px",
      height: 15,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },
  }));