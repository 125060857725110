import E55cLogo from "../themes/e55c/toolbar-logo.png";
import Yes55logo from "../themes/yes55/toolbar-logo.png";
import GcaLogo from "../themes/gca/toolbar-logo.png";
import {AppBar, Layout, TitlePortal} from "react-admin";
import Box from "@mui/material/Box";

import AppMenu from "./AppMenu";
import appConfig from "../../config";
import {Typography} from "@mui/material";
import * as React from "react";
import AppUserMenu from "./AppUserMenu";

const tenant = appConfig.TENANT;

const MyAppBar = () => {
    let logo = null;

    switch (tenant) {
        case 'e55c':
            logo = E55cLogo;
            break;

        case 'yes55':
            logo = Yes55logo;
            break;

        case 'gca':
            logo = GcaLogo;
            break;
    }

    return (
        <AppBar elevation={1} userMenu={<AppUserMenu/>}>
            <Box sx={{display: "flex"}} flex="1">
                <Typography
                    overflow="hidden"
                    variant="h6"
                    color="inherit"
                    sx={{border: 1, borderRadius: 3, paddingX: 1, marginRight: 1, display: {xs: "none", md: "block"}}}
                >
                    Angus
                </Typography>
                <TitlePortal sx={{display: {xs: "none", sm: "block"}}}/>
            </Box>
            <Box flex={1}/>
            <Box component="img" src={logo!} sx={{height: 48, marginRight: "1em"}}/>
            <Box flex={2}/>
        </AppBar>
    );
};

// const AngusLayout = ({children, ...rest }: any) => {
//     return <Layout {...rest} appBar={MyAppBar} menu={AppMenu} sx={{display: "flex", flexDirection: "column"}}>
//         <Box sx={{flex: 1}}>
//             {children}
//         </Box>
//         <Box sx={{textAlign: "center"}}>Angus</Box>
//     </Layout>
// }

const AppLayout = (props: any) => {
    return <Layout  {...props} appBar={MyAppBar} menu={AppMenu} />
};

export default AppLayout;
