import {
    EditButton, ListButton,
    Show, SimpleShowLayout, TextField, TopToolbar,
} from 'react-admin';

import React from "react";
import TariffsTabs from "./components/TariffsTabs";
import {useIsAllowed} from "../../../helpers/useIsAllowed";
import {AppPermissions} from "../../data/constants/permissions";

const Actions = () => {
    const {isAllowed} = useIsAllowed();

    return (
        <TopToolbar>
            {isAllowed(AppPermissions.TariffGroupsManage) && <EditButton/>}
            <ListButton/>
        </TopToolbar>
    );
};

export const TariffgroupShow = () => (
    <Show actions={<Actions/>}>
        <SimpleShowLayout>
            <TextField source="tariffgroup_id" />
            <TariffsTabs/>
        </SimpleShowLayout>
    </Show>
);
