import { AngusPaletteProvider } from "../../components/provider/AngusPaletteProvider";
import CdrSection from "../../features/dashboard/session/CdrSection";
import CdrsDetailsStatsTable from "./components/CdrsDetailsStatsTable";

interface PageStatsCdrsProps{
    displayDetailsTable: boolean;
}

export const PageStatsCdrs: React.FC<PageStatsCdrsProps> = ({displayDetailsTable}) => {
    return (<>
        <AngusPaletteProvider>
            <CdrSection/>
            { displayDetailsTable ? <CdrsDetailsStatsTable/> : <></> }
        </AngusPaletteProvider>
    </>);
}
