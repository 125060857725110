// todo : duplicata from node-api 
export enum DashboardDateOption {
    TODAY = "TODAY", 
    ONE_DAY = "ONE_DAY", 
    SEVEN_DAYS = "SEVEN_DAYS", 
    THIRTY_DAYS = "THIRTY_DAYS", 
    NINETY_DAYS = "NINETY_DAYS", 
    ONE_HUNDRED_EIGHTY_DAYS = "ONE_HUNDRED_EIGHTY_DAYS", 
    YEAR_TO_DATE = "YEAR_TO_DATE", 
    ONE_YEAR = "ONE_YEAR"
}

export const getDashboardDateOptionFromString = (dateOption: string | null) : DashboardDateOption => {    
    if(dateOption === null) {
        return DashboardDateOption.TODAY;
    }
    const dateOptEnum = dateOption as keyof typeof DashboardDateOption;
    for(let enumMember in DashboardDateOption) {
        if(DashboardDateOption[enumMember as keyof typeof DashboardDateOption] === DashboardDateOption[dateOptEnum]) {
            return DashboardDateOption[dateOptEnum];
        }
    }
    return DashboardDateOption.TODAY;
}

// TODO : 4 real
export function translateDateOption(dateOpt: DashboardDateOption): string {
    switch(dateOpt) {
        case DashboardDateOption.TODAY:
            return "AUJ";
        case DashboardDateOption.ONE_DAY:
            return "1J";
        case DashboardDateOption.SEVEN_DAYS:
            return "7J";
        case DashboardDateOption.THIRTY_DAYS:
            return "30J";
        case DashboardDateOption.NINETY_DAYS:
            return "90J";
        case DashboardDateOption.ONE_HUNDRED_EIGHTY_DAYS:
            return "180J";
        case DashboardDateOption.YEAR_TO_DATE:
            return "YTD";
        case DashboardDateOption.ONE_YEAR:
            return "1A";
    }
}