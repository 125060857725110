import { createContext, ReactNode, useCallback, useEffect, useState } from "react";
import AngusPalette from "../../data/constants/AngusPalette";
import { useStore } from "react-admin";

const defaultPaletteContextParam = {
    palette: new AngusPalette(),
    updatePalette: (d: string[]) => {}
}

interface AngusPaletteContextProps {
    palette: AngusPalette;
    updatePalette: (domain: string[]) => void;
}

interface AngusPaletteProviderProps {
    children: ReactNode
}

const AngusPaletteContext = createContext<AngusPaletteContextProps>(defaultPaletteContextParam)

export default AngusPaletteContext;

export const AngusPaletteProvider : React.FC<AngusPaletteProviderProps> = ({children}) => {

    const [palette] = useState(new AngusPalette())
    const [theme] = useStore("theme")

    const updatePalette = useCallback((domain: string[]) => {
        palette.refresh(domain, theme === "dark");
    }, [palette, theme]);

    useEffect(() => {
        updatePalette([""])
    }, [theme, updatePalette])

    return (
        <AngusPaletteContext.Provider value={{ palette, updatePalette }}>
            {children}
        </AngusPaletteContext.Provider>
    );
}