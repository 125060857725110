import {useRecordContext, useTranslate} from "react-admin";
import {Cdr} from "../../../data/cdr";
import Typography from "@mui/material/Typography";
import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {toFrenchDate} from "../../../../helpers/helper";

export default function CdrOverridesHistory() {
    const record = useRecordContext<Cdr>();
    const translate = useTranslate();

    if (!record?.overrides) {
        return null;
    }

    return (
        <Card sx={{marginY: 2}}>
            <CardContent>
                <Typography variant="h6">{translate('resources.cdrs.overrides.card.title')}</Typography>
                <Table>
                    <TableHead>
                      <TableRow>
                          <TableCell align="left">
                            {translate('resources.cdrs.overrides.card.table.changes')}
                          </TableCell>
                          <TableCell align="right">
                              {translate('resources.cdrs.overrides.card.table.updatedAt')}
                          </TableCell>
                          <TableCell align="right">
                              {translate('resources.cdrs.overrides.card.table.updatedBy')}
                          </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                        {record.overrides.map((item, index) => {
                            return <TableRow
                                key={index}
                            >
                                <TableCell align="left"><ul>{
                                    Object.keys(item.changes).map((key, index) => {
                                        return <li key={index}>{translate(`resources.cdrs.fields.cdr.${key}`)} : {item.changes[key].previous + ' -> ' + item.changes[key].new}</li>
                                    })
                                }</ul></TableCell>
                                <TableCell align="right">{toFrenchDate(item.updatedAt)}</TableCell>
                                <TableCell align="right">{item.updatedBy}</TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
}
